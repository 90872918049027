import React, { Fragment } from 'react';
import { NavLink } from 'react-router-dom';
import BannerDash from './bannerDash';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import CardDashComp from './cardDashComp';

function DashboardComp({ formattedDate }) {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: 1500,
  };

  return (
    <Fragment>
      <div>
        <Slider {...settings}>
          <div>
            <BannerDash
              title="Bienvenido a tu dashboard"
              formattedDate={formattedDate}
              text="Dashboard"
            />
          </div>
          <div>
            <BannerDash
              title="Administra tus productos"
              formattedDate={formattedDate}
              text="Dashboard"
            />
          </div>
        </Slider>
      </div>

      <h1 className="title-dash-sec">Secciones de tu dashboard</h1>

      <div className="container-fluid">
        <div className="row">
          <div className="col-xl-4">
            <NavLink to="/dashboard/productos" className="card-link">
              <CardDashComp title="Listado de productos" />
            </NavLink>
          </div>
          <div className="col-xl-4">
            <NavLink to="/dashboard/ventas" className="card-link">
              <CardDashComp title="Mis ventas" />
            </NavLink>
          </div>
          <div className="col-xl-4">
            <NavLink to="/dashboard/suscripciones" className="card-link">
              <CardDashComp title="Suscripciones" />
            </NavLink>
          </div>
          <div className="col-xl-4">
            <NavLink to="/dashboard/configuraciones" className="card-link">
              <CardDashComp title="Configuraciones" />
            </NavLink>
          </div>
          <div className="col-xl-4">
            <NavLink to="/dashboard/finanzas" className="card-link">
              <CardDashComp title="finanzas" />
            </NavLink>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default DashboardComp;
