import React, { Fragment, useEffect, useState } from 'react';
import axios from 'axios';
import { Button } from 'react-bootstrap';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import BannerDash from './bannerDash';
import { motion } from 'framer-motion';
import { ClipLoader, RingLoader } from 'react-spinners'; // Importa ClipLoader

function FinanzasComp({ formattedDate }) {
  const [ventasDiarias, setVentasDiarias] = useState(0);
  const [historialVentas, setHistorialVentas] = useState([]);
  const [loading, setLoading] = useState(true); 
  const [ventasDiariasCantidad, setVentasDiariasCantidad] = useState(true); 
  const [totalAcumulado, setTotalAcumulado] = useState(0);
  

  useEffect(() => {
    const obtenerHistorial = async () => {
      try {
        const response = await axios.get('https://ecommerce-backend-self-phi.vercel.app/registros-dia');
        const registros = response.data;
        setHistorialVentas(response.data);
        const acumulado = registros.reduce((total, registro) => total + registro.monto, 0);
        setTotalAcumulado(acumulado); 
      } catch (error) {
        console.error('Error obteniendo el historial de ventas:', error);
      }
    };

    obtenerHistorial();
  }, []);

  useEffect(() => {
    const obtenerVentas = async () => {
      try {
        const response = await axios.get('https://ecommerce-backend-self-phi.vercel.app/ventas');
        const ventasEnCurso = response.data.filter(
          (venta) =>
            venta.estado === 'en curso' &&
            new Date(venta.fechaHora).toLocaleDateString() === new Date().toLocaleDateString()
        );

        const ventasDiariasCantidadVar = ventasEnCurso.length;
        setVentasDiariasCantidad(ventasDiariasCantidadVar);
        const totalVentasEnCurso = ventasEnCurso.reduce((total, venta) => total + venta.total, 0);
        setVentasDiarias(totalVentasEnCurso);
        setLoading(false); 
      } catch (error) {
        console.error('Error obteniendo ventas:', error);
        setLoading(false);
      }
    };

    obtenerVentas();

    const intervalId = setInterval(() => {
      const now = new Date();
      if (now.getHours() === 23 && now.getMinutes() === 59) {
        guardarRegistroDelDia();
      }
    }, 60000);

    return () => clearInterval(intervalId);
  }, [formattedDate]);



  const guardarRegistroDelDia = async () => {
    const nuevoRegistro = {
      fecha: formattedDate,
      monto: ventasDiarias,
    };

    try {
      const response = await axios.post('https://ecommerce-backend-self-phi.vercel.app/registros-dia', nuevoRegistro);
      if (response.status === 201) {
        setHistorialVentas((prevHistorial) => [...prevHistorial, nuevoRegistro]);

        setVentasDiarias(0);
      }
    } catch (error) {
      console.error('Error guardando el registro del día:', error);
    }
  };

  return (
    <Fragment>
      <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 0.15 }}>
        <div className="container-web-viewport">
          <div>
            <BannerDash title="Finanzas" formattedDate={formattedDate} text="Dashboard" />
          </div>
          <div className="container">
            <div className="row">
              <div className="col-xl-4 col-lg-4 col-md-6">
                <div className="card-col-ctn">
                  <p className="text-card-col-ctn">Ventas del día:</p>
                  <h6 className="text-ctn-total-col span-big">
                    ${loading ? <RingLoader size={30} color={"#000"} /> : ventasDiarias.toLocaleString()}
                  </h6>
                </div>
              </div>
              <div className="col-xl-4 col-lg-4 col-md-6">
                <div className="card-col-ctn">
                  <p className="text-card-col-ctn">Ventas totales</p>
                  <h6 className="text-ctn-total-col">
                    {loading ? <RingLoader size={30} color={"#000"} /> : ventasDiariasCantidad}
                  </h6>
                </div>
              </div>
              <div className="col-xl-4 col-lg-4 col-md-6">
                <div className="card-col-ctn">
                  <p className="text-card-col-ctn">Ganancias</p>
                  <h6 className="text-ctn-total-col span-big">
                    ${loading ? <RingLoader size={30} color={"#000"} /> : ventasDiarias.toLocaleString()}
                  </h6>
                </div>
              </div>
            </div>
          </div>

          <div className='container-fluid'>
            <div className='row'>
              <div className='col-xl-6 col-lg-6 col-md-6'> 
                  <div className="historial-ventas">
                <h4>Historial de Ventas Diarias</h4>
                <ul>
                  {historialVentas.map((venta, index) => (
                    <li className='li-venta-list-data' key={index}>
                      Fecha: {venta.fecha} - Monto: {venta.monto}
                    </li>
                  ))}
                </ul>
              </div>
              </div>

               <div className="col-xl-6 col-lg-6 col-md-6">
                <div className="card-col-ctn card-span-finan">
                  <p className="text-card-col-ctn">Total Acumulado</p>
                  <h6 className="text-ctn-total-col span-big">
                 ${loading ? <RingLoader size={30} color={"#000"} /> : totalAcumulado.toLocaleString()}
                  </h6>
                </div>
              </div>

            </div>
          </div>

          
        </div>
      </motion.div>
    </Fragment>
  );
}

export default FinanzasComp;
