import React, { Fragment, useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import Carrito from '../comp/carrito';
import Banner from '../comp/banner';
import { useNavigate } from 'react-router-dom';
import HomeCarousel from '../comp/homeCarusel';
import '../styles/home.css';
import SwiperHome from '../comp/swiperHome';
import axios from 'axios';
import Footer from '../comp/footer';
import HeaderMobileHome from '../comp/headerMobileHome';
import SubscriptionModal from '../comp/suscripciones';
import LoadingScreen from '../comp/loadingScreen';

const fuzzyMatch = (str, query) => {
  if (typeof str !== 'string' || typeof query !== 'string') {
    return false;
  }
  return str.toLowerCase().startsWith(query.toLowerCase());
};

function Home() {
  const [productos, setProductos] = useState([]);
  const [activeCartShow, setActiveCartShow] = useState(false);
  const [activeAside, setActiveAside] = useState(false);
  const [bannerSuccess, setBannerSuccess] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(
    'Todos los artículos',
  );
  const [searchTerm, setSearchTerm] = useState('');
  const [bannerText, setBannerText] = useState('');
  const [bannerText2, setBannerText2] = useState('');
  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();

  useEffect(() => {
    const fetchProductos = async () => {
      try {
        const response = await axios.get(
          'https://ecommerce-backend-self-phi.vercel.app/productos',
        );
        const productosActivos = response.data.filter((producto) => producto.status === true);
        setProductos(productosActivos);

        const bannerResponse = await axios.get(
          'https://ecommerce-backend-self-phi.vercel.app/config',
        );
        setBannerText(bannerResponse.data.texto);
        setBannerText2(bannerResponse.data.texto2);
      } catch (error) {
        console.error('Error fetching products:', error);
      }finally {
        setLoading(false); 
      }
      
    };

    fetchProductos();
  }, []);

  const handleActiveCart = () => {
    setActiveCartShow(true);
  };

  const handleActiveAside = () => {
    setActiveAside(true);
  };

  const handleCloseCart = () => {
    setActiveCartShow(false);
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleCloseAside = () => {
    setActiveAside(false);
  };

  const onProductoClick = (productoId) => {
    navigate(`/producto/${productoId}`);
  };

  const handleCategoryClick = (category) => {
    setSelectedCategory(category);
    setSearchTerm('');
  };

  const filteredProducts = productos.filter((producto) => {
    const matchesCategory =
      selectedCategory === 'Todos los artículos' ||
      fuzzyMatch(producto.nombre, selectedCategory.substring(0, 3));
    const matchesSearch = fuzzyMatch(producto.nombre, searchTerm);
    return matchesCategory && matchesSearch;
  });

  return (
    <Fragment>
       {loading && <LoadingScreen />}
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1.4 }}
      >
        <HeaderMobileHome
          handleActiveCart={handleActiveCart}
          activeAside={activeAside}
          handleActiveAside={handleActiveAside}
          handleCloseAside={handleCloseAside}
        />
        <Banner banner={bannerSuccess} title="Producto agregado" />
        <Carrito
          activeCart={activeCartShow}
          handleCloseCart={handleCloseCart}
        />
           <SubscriptionModal />

        <div className="header-top-banner-products">
          <h1 className="text-inside-banner-products">
          {bannerText2}
          </h1>
        </div>

        <header className="header-general-products">
          <img
            className="img-ptofile-brand"
            src={require('../images/logo.png')}
            alt="logo"
          />

          <input
            className="input-header-search-products"
            type="text"
            placeholder="Buscar productos"
            value={searchTerm}
            onChange={handleSearchChange}
          />

          <div className="container-row-header-options">
            <li
              className="option-header"
              onClick={() => navigate('/productos')}
            >
              Articulos
            </li>
            <li
              className="option-header"
              onClick={() => navigate('/productos')}
            >
              Categorías
            </li>
            <li
              className="option-header"
              onClick={() => navigate('/nosotros')}
            >
              Sobre Nosotros
            </li>
            <li
              className="option-header"
              onClick={() => navigate('/compras-mayoristas')}
            >
              Compras Mayoristas
            </li>
          </div>

          <div className="card-cart-products" onClick={handleActiveCart}>
            <img
              className="img-card-cart-products"
              src={require('../images/cart.png')}
              alt="cart"
            />
          </div>
        </header>

        <section className="banner-top-home">
          <div className="banner-img-inside">
            <p className="text-img-inside">
             {bannerText}
            </p>
          </div>
        </section>

        <section className="container-text-trend-home">
          <h2 className="title-trend-home">Infaltables</h2>
          <button
            className="btn-title-trend-home"
            onClick={() => navigate('/productos/categoria/Todos%20los%20artículos')}
          >
            Ver colección completa
          </button>
        </section>

        <section className="sectiom-swiper-home">
          <SwiperHome products={productos} onProductoClick={onProductoClick} />
        </section>

        <section className="container-fluid">
          <div className="row">
            <div
              className="col-lg-4"
              onClick={() => navigate('/productos/categoria/Descuentos')}
            >
              <div className="poster-1">
                <p className="text-poster">Ofertas</p>
              </div>
            </div>
            <div
              className="col-lg-4"
              onClick={() => navigate('/productos/categoria/Invierno')}
            >
              <div className="poster-2">
                <p className="text-poster">Invierno</p>
              </div>
            </div>
            <div
              className="col-lg-4"
              onClick={() => navigate('/productos/categoria/Verano')}
            >
              <div className="poster-3">
                <p className="text-poster">Verano</p>
              </div>
            </div>
          </div>
        </section>

        <section className="container-text-trend-home">
          <h2 className="title-trend-home">
            No te pierdas nuestros descuentos exclusivos
          </h2>
          <button
            className="btn-title-trend-home"
            onClick={() => navigate('/productos/categoria/Todos%20los%20artículos')}
          >
            Ver colección completa
          </button>
        </section>

        <section className="sectiom-swiper-home">
          <SwiperHome products={productos} onProductoClick={onProductoClick} />
        </section>

        <img
          className="img-static-w"
          src={require('../images/w.png')}
          alt="w"
        />

        <Footer />
      </motion.div>
    </Fragment>
  );
}

export default Home;
