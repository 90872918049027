import React, { Fragment } from 'react';
import '../styles/footer.css';
import { useNavigate } from 'react-router-dom';

function Footer() {
  const navigate = useNavigate();

  return (
    <Fragment>
      <footer className="container-footer">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-3 col-md-3 col-12 col-foot">
              <h1 className="title-top-foot">Nuestros Artículos</h1>
              <li
                className="item-foot"
                onClick={() => navigate('/productos/categoria/Buzos')}
              >
                Buzos
              </li>
              <li
                className="item-foot"
                onClick={() => navigate('/productos/categoria/Camperas')}
              >
                Camperas
              </li>
              <li
                className="item-foot"
                onClick={() => navigate('/productos/categoria/Remeras')}
              >
                Remeras
              </li>
              <li
                className="item-foot"
                onClick={() => navigate('/productos/categoria/Zapatillas')}
              >
                Zapatillas
              </li>
            </div>

            <div className="col-lg-3 col-md-3 col-12 col-fot">
              <h1 className="title-top-foot">Más información</h1>
              <li className="item-foot" onClick={() => navigate('/terminos-y-condiciones')}>Términos y condiciones</li>
              <li className="item-foot" onClick={() => navigate('/preguntas-frecuentes')}>Preguntas Frecuentes</li>
              <li className="item-foot">Contáctanos</li>
            </div>
            <div className="col-lg-3 col-md-3 col-12  col-foot">
              <h1 className="title-top-foot">Ubicación</h1>
              <div className="contaiener-iframe">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d99611.45927458827!2d-62.33877404821463!3d-38.72106669464275!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x95edbcabdc1302bd%3A0x76d1d88d241e7a11!2sBah%C3%ADa%20Blanca%2C%20Provincia%20de%20Buenos%20Aires!5e0!3m2!1ses!2sar!4v1723482877764!5m2!1ses!2sar"
                  width="250"
                  height="200"
                  allowfullscreen=""
                  loading="lazy"
                  referrerpolicy="no-referrer-when-downgrade"
                ></iframe>
              </div>
            </div>
            <div className="col-lg-3 col-md-3 col-12  col-foot">
              <h1 className="title-top-foot">Sobre Nosotros</h1>
              <img
                className="img-footer-social-media"
                src={require('../images/i.png')}
                alt="i"
              />
              <img
                className="img-footer-social-media"
                src={require('../images/f.png')}
                alt="i"
              />
              <img
                className="img-footer-social-media"
                src={require('../images/t.png')}
                alt="i"
              />
              <img
                className="img-footer-social-media"
                src={require('../images/w.png')}
                alt="i"
              />
            </div>
          </div>
          <h1 className="text-important-agency">
            X menos más prendas, derechos reservados, desarrollado por Agencia
            CREAS y Agencia JGTC.
          </h1>
        </div>
      </footer>
    </Fragment>
  );
}

export default Footer;
