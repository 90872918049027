import React from 'react';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';

function DashboardSalesDetails({
  sale,
  formData,
  isEditing,
  onClose,
  onEditChange,
  onUpdate,
  onDelete,
  onEditToggle,
}) {
  const hasProducts = sale?.productos?.length > 0;

  const generatePDF = () => {
    const input = document.getElementById('pdf-content');
    html2canvas(input, { useCORS: true, scale: 2 }).then((canvas) => {
      const imgData = canvas.toDataURL('image/png');
      const pdf = new jsPDF('p', 'mm', 'a4');
      const imgWidth = 210; // A4 width in mm
      const pageHeight = 295; // A4 height in mm
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      let heightLeft = imgHeight;

      let position = 0;

      pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
      heightLeft -= pageHeight;

      while (heightLeft >= 0) {
        position = heightLeft - imgHeight;
        pdf.addPage();
        pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;
      }

      pdf.save(`venta_${sale.id}.pdf`);
    });
  };

  return (
    <div className="details-container">
      <h2 className="title-detail-ventas-comp">Detalles de Venta</h2>
      {isEditing ? (
        <form onSubmit={onUpdate} className="edit-form">
          <div className="form-group">
            <label htmlFor="nombre">Nombre:</label>
            <input
              type="text"
              id="nombre"
              name="nombre"
              value={formData.nombre || ''}
              onChange={onEditChange}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="apellido">Apellido:</label>
            <input
              type="text"
              id="apellido"
              name="apellido"
              value={formData.apellido || ''}
              onChange={onEditChange}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="fechaHora">Fecha y Hora:</label>
            <input
              type="datetime-local"
              id="fechaHora"
              name="fechaHora"
              value={formData.fechaHora || ''}
              onChange={onEditChange}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="estado">Estado:</label>
            <select
              id="estado"
              name="estado"
              value={formData.estado || ''}
              onChange={onEditChange}
              required
            >
              <option value="cancelada">cancelada</option>
              <option value="en curso">en curso</option>
              <option value="finalizada">finalizada</option>
            </select>
          </div>
          <div className="form-group">
            <label htmlFor="total">Total:</label>
            <input
              type="number"
              id="total"
              name="total"
              value={formData.total || ''}
              onChange={onEditChange}
              required
            />
          </div>
          <button type="submit">Actualizar</button>
        </form>
      ) : (
        <div>
          <div id="pdf-content" style={{ padding: '50px' }}>
            <p><strong>ID Venta (Identificador):</strong> {sale.id}</p>
            <p><strong>Nombre:</strong>  {sale.nombre || 'No disponible'}</p>
            <p><strong>Apellido:</strong> {sale.apellido || 'No disponible'}</p>
            <p><strong>Dirección:</strong>  {sale.direccion || 'No disponible'}</p>
            <p><strong>Código Postal:</strong>  {sale.codigoPostal || 'No disponible'}</p>
            <p><strong>Ciudad:</strong>  {sale.ciudad || 'No disponible'}</p>
            <p><strong>Provincia:</strong>  {sale.provincia || 'No disponible'}</p>
            <p><strong>Celular:</strong>  {sale.celular || 'No disponible'}</p>
            <p><strong>Correo Electrónico:</strong> {sale.correo || 'No disponible'}</p>
            <p>
            <strong>Fecha y Hora</strong> {' '}
              {sale.fechaHora
                ? new Date(sale.fechaHora).toLocaleString()
                : 'No disponible'}
            </p>
            <p><strong>Estado del pedido:</strong>  {sale.estado || 'No disponible'}</p>
            <p><strong>Total Compra:</strong>  ${sale.total ? sale.total.toLocaleString() : '0.00'}</p>

            {hasProducts && (
              <div>
                <h3 className="title-detail-ventas-comp">Productos Vendidos</h3>
                <div className="span-container-sales-mobile">
                  <table className="table">
                    <thead>
                      <tr>
                        <th>ID</th>
                        <th>Nombre</th>
                        <th>Color</th>
                        <th>Talle</th>
                        <th>Precio</th>
                        <th>Cantidad</th>
                      </tr>
                    </thead>
                    <tbody>
                      {sale.productos.map((producto) => (
                        <tr key={producto.id}>
                          <td>{producto.id || 'No disponible'}</td>
                          <td>{producto.nombre || 'No disponible'}</td>
                          <td>{producto.color || 'No disponible'}</td>
                          <td>{producto.talle || 'No disponible'}</td>
                          <td>
                            $
                            {producto.precio
                              ? producto.precio.toLocaleString()
                              : '0.00'}
                          </td>
                          <td>{producto.cantidad || 0}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            )}
          </div>

          <button className="btn-sales-comp" onClick={onEditToggle}>
            Editar
          </button>
          <button className="btn-sales-comp" onClick={onDelete}>
            Eliminar
          </button>
          <button className="btn-sales-comp" onClick={onClose}>
            Cerrar
          </button>
          <button className="btn-sales-comp" onClick={generatePDF}>
            Descargar PDF
          </button>
        </div>
      )}
    </div>
  );
}

export default DashboardSalesDetails;
