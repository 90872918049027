import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Productos from '../pages/productos';
import { CarritoProvider } from '../services/carritoController';
import Login from './login';
import Dashboard from '../pages/dashboard';
import ProductoDetalle from './productoDetalle';
import ProductosComp from './productosComp';
import VentasComp from './ventasComp';
import Home from '../pages/home';
import Confirmacion from './confirmacion';
import Nosotros from '../pages/nosotros';
import Mayorista from '../pages/mayorista';
import Terminos from '../pages/terminos';
import Preguntas from '../pages/preguntas';

function App() {
  const [date, setDate] = useState(new Date());
  const options = { day: 'numeric', month: 'numeric' };
  const formattedDate = date.toLocaleDateString(undefined, options);

  return (
    <CarritoProvider>
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/productos" element={<Productos />} />
          <Route path="/producto/:id" element={<ProductoDetalle />} />
          <Route
            path="/productos/categoria/:category"
            element={<Productos />}
          />
          <Route path="/dashboard" element={<Login />} />
          <Route path="/dashboard/*" element={<Dashboard />} />
          <Route
            path="/dashboard/listado-productos"
            element={<ProductosComp formattedDate={formattedDate} />}
          />
          <Route
            path="/dashboard/listado-ventas"
            element={<VentasComp formattedDate={formattedDate} />}
          />
          <Route path="/confirmacion" element={<Confirmacion />} />
          <Route path="/nosotros" element={<Nosotros />} />
          <Route path="/compras-mayoristas" element={<Mayorista />} />
          <Route path="/terminos-y-condiciones" element={<Terminos />} />
          <Route path="/preguntas-frecuentes" element={<Preguntas />} />
        </Routes>
      </Router>
    </CarritoProvider>
  );
}

export default App;
