import React, { Fragment, useState, useEffect, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import '../styles/productos.css';
import ProductoArt from '../comp/productoArt';
import axios from 'axios';
import { useCarrito } from '../services/carritoController';
import Carrito from '../comp/carrito';
import Banner from '../comp/banner';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { motion } from 'framer-motion';
import Slider from 'react-slick';
import BannerDash from '../comp/bannerDash';
import HeaderMobileHome from '../comp/headerMobileHome';
import LoadingScreen from '../comp/loadingScreen';
import Footer from '../comp/footer';
import BannerPrWeb from '../comp/bannerPrWeb';

const fuzzyMatch = (str, query) => {
  if (typeof str !== 'string' || typeof query !== 'string') {
    return false;
  }
  return str.toLowerCase().startsWith(query.toLowerCase());
};

function Productos() {
  const { category } = useParams();
  const [productos, setProductos] = useState([]);
  const { agregarAlCarrito } = useCarrito();
  const [activeCartShow, setActiveCartShow] = useState(false);
  const [bannerSuccess, setBannerSuccess] = useState(false);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [activeAside, setActiveAside] = useState(false);
  const [loadingScreen, setLoadingScreen] = useState(true);
  const [categorias, setCategorias] = useState([]);
  const [expandedCategory, setExpandedCategory] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(category || 'Todos los artículos'); 

  const navigate = useNavigate();

  const categoriasContainerRef = useRef(null);


useEffect(() => {
  const fetchCategorias = async () => {
    try {
      const response = await axios.get('https://ecommerce-backend-self-phi.vercel.app/categorias');
      setCategorias(response.data);
    } catch (error) {
      console.error('Error fetching categories:', error);
    }
  };

  fetchCategorias();
}, []);


  useEffect(() => {
    const fetchProductos = async () => {
      try {
        const response = await axios.get(
          'https://ecommerce-backend-self-phi.vercel.app/productos',
        );
        setProductos(response.data);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching products:', error);
      }finally{
        setLoadingScreen(false);
      }
    };

    fetchProductos();
  }, []);


  const [date, setDate] = useState(new Date());
  const options = { day: 'numeric', month: 'numeric' };
  const formattedDate = date.toLocaleDateString(undefined, options);

  const handleActiveCart = () => {
    setActiveCartShow(true);
  };

  const handleActiveAside = () => {
    setActiveAside(true);
  };

  const handleCloseCart = () => {
    setActiveCartShow(false);
  };

  const handleShowBanner = () => {
    setBannerSuccess(true);
  };

  const handleCloseAside = () => {
    setActiveAside(false);
  };

  useEffect(() => {
    let timeout;
    if (bannerSuccess) {
      timeout = setTimeout(() => {
        setBannerSuccess(false);
      }, 3000);
    } else {
      setBannerSuccess(false);
    }

    return () => clearTimeout(timeout);
  }, [bannerSuccess]);

  const onProductoClick = (productoId) => {
    navigate(`/producto/${productoId}`);
  };


  useEffect(() => {
    const centerSelectedCategory = () => {
      const selectedCategoryElement = categoriasContainerRef.current.querySelector(`[data-categoria="${category || 'Todos los artículos'}"]`);
      if (selectedCategoryElement) {
        const containerWidth = categoriasContainerRef.current.offsetWidth;
        const elementOffset = selectedCategoryElement.offsetLeft;
        const elementWidth = selectedCategoryElement.offsetWidth;
        const scrollPosition = elementOffset - containerWidth / 2 + elementWidth / 2;

        categoriasContainerRef.current.scrollTo({
          left: scrollPosition,
          behavior: 'smooth',
        });
      }
    };

    setTimeout(centerSelectedCategory, 100);
  }, [categorias, category]);



  const handleCategoryClick = (categoriaNombre) => {
    setSelectedCategory(categoriaNombre);
    navigate(`/productos/categoria/${categoriaNombre}`);
    
    const selectedCategoryElement = categoriasContainerRef.current.querySelector(`[data-categoria="${categoriaNombre}"]`);
    if (selectedCategoryElement) {
      const containerWidth = categoriasContainerRef.current.offsetWidth;
      const elementOffset = selectedCategoryElement.offsetLeft;
      const elementWidth = selectedCategoryElement.offsetWidth;
      const scrollPosition = elementOffset - containerWidth / 2 + elementWidth / 2;

      categoriasContainerRef.current.scrollTo({
        left: scrollPosition,
        behavior: 'smooth',
      });
    }
  };


  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const filteredProducts = productos.filter((producto) => {
    const matchesCategory =
      !category ||
      category === 'Todos los artículos' ||
      producto.categoria === category ||
      producto.subcategoria === category; 
  
    const matchesSearch = fuzzyMatch(producto.nombre, searchTerm);
    
    return matchesCategory && matchesSearch;
  });
  

  const totalProductosCategoria = category === 'Todos los artículos'
  ? productos.length
  : productos.filter(
      (producto) => producto.categoria === category || producto.subcategoria === category
    ).length;
  

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: 1500,
  };

  return (
    <Fragment>
       {loadingScreen && <LoadingScreen />}
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.15 }}
      >
        <HeaderMobileHome
          handleActiveCart={handleActiveCart}
          activeAside={activeAside}
          handleActiveAside={handleActiveAside}
          handleCloseAside={handleCloseAside}
        />

        <Banner banner={bannerSuccess} title="Producto agregado" />
        <Carrito
          activeCart={activeCartShow}
          handleCloseCart={handleCloseCart}
        />

        <div className="container-web-viewport">
          <div className="header-top-banner-products">
            <h1 className="text-inside-banner-products">
              El envío es gratis a partir de tu compra de 12 unidades en
              artículos
            </h1>
          </div>

          <header className="header-general-products">
            <div className="profile-brand">
              <img
                onClick={() => navigate('/')}
                className="img-ptofile-brand"
                src={require('../images/logo.png')}
                alt="logo"
              />
            </div>

            <input
              className="input-header-search-products"
              type="text"
              placeholder="Buscar productos"
              value={searchTerm}
              onChange={handleSearchChange}
            />

            <div className="container-row-header-options">
            <li
              className={`option-header ${!category || category === 'Todos los artículos' ? 'selected-tab-pr' : ''}`}
              onClick={() => handleCategoryClick('Todos los artículos')}
            >
              Todos los productos
            </li>

              {categorias.map((categoria) => (
                <li
                  key={categoria.id}
                  className={`option-header ${category === categoria.nombre ? 'selected-tab-pr' : ''}`}
                  onClick={() => handleCategoryClick(categoria.nombre)}
                >
                  {categoria.nombre}
                </li>
              ))}
            </div>

            <div className="card-cart-products" onClick={handleActiveCart}>
              <img
                className="img-card-cart-products"
                src={require('../images/cart.png')}
                alt="cart"
              />
            </div>
          </header>

          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-2 col-md-2 col-3 span-tab-products">
                <div className="tab-left-products-land">
                  <h1 className="title-tab-left">Categorías</h1>
              <li
                className={`item-select-tab ${!category || category === 'Todos los artículos' ? 'selected-tab-pr' : ''}`}
                onClick={() => handleCategoryClick('Todos los artículos')}
              >
                Todos ({productos.length}) 
              </li>

              {categorias.map((categoria) => {
                const totalProductosCategoria = productos.filter(
                  (producto) => producto.categoria === categoria.nombre
                ).length;

                return (
                  <div key={categoria.id}>
                    <li
                      className={`item-select-tab ${category === categoria.nombre ? 'selected-tab-pr' : ''}`}
                      onClick={() => handleCategoryClick(categoria.nombre)}
                    >
                      {categoria.nombre} ({totalProductosCategoria}) 
                      {categoria.subcategorias && categoria.subcategorias.length > 0 && (
                        <span className={`arrow-icon ${expandedCategory === categoria.nombre ? 'down' : 'right'}`}>
                          {expandedCategory === categoria.nombre ? '▼' : '▶'}
                        </span>
                      )}
                    </li>

                    {expandedCategory === categoria.nombre && categoria.subcategorias && (
                      <ul className="subcategoria-dropdown">
                        {categoria.subcategorias.map((subcat) => {
                          const totalProductosSubcategoria = productos.filter(
                            (producto) => producto.subcategoria === subcat.nombre.nombre
                          ).length;

                          return (
                            <li
                              key={subcat.id}
                              className="subcategoria-item"
                              onClick={() => handleCategoryClick(subcat.nombre.nombre)}
                            >
                              {subcat.nombre.nombre} ({totalProductosSubcategoria})
                            </li>
                          );
                        })}
                      </ul>
                    )}
                  </div>
                );
              })}

                                
                </div>
              </div>
              <div className="col-lg-10 col-md-10 col-9 span-canvas-products">
                <div className="container">
                  <h1 className="text-search">
                    Resultados de búsqueda para{' '}
                    <p className='text-span-pr-sec'>{category || 'Todos los artículos'} ({totalProductosCategoria})</p>
                  </h1>
                </div>

                <div className="container">
                  <div className="row">
                    {loading
                      ? Array(6)
                          .fill()
                          .map((_, index) => (
                            <div className="col-3 mb-3" key={index}>
                              <Skeleton height={300} />
                            </div>
                          ))
                      : filteredProducts.map((producto) => (
                          <ProductoArt
                            key={producto.id}
                            producto={producto}
                            agregarAlCarrito={agregarAlCarrito}
                            handleShowBanner={handleShowBanner}
                            onProductoClick={onProductoClick}
                          />
                        ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container-mobile-viewport container container-pr-mobile-span">
          <div className="banner-top-trend">
            <h1 className="text-trend-top">Más prendas por menos</h1>
          </div>

          <div>
            <Slider {...settings}>
              <div>
                <BannerPrWeb
                  title="Listado de artículos"
                  text="Productos"
                  formattedDate={formattedDate}
                  imagen={require('../images/poster-bg.png')}
                />
              </div>
              <div>
                <BannerPrWeb
                  title="Lo mejor de lo mejor"
                  text="Productos"
                  formattedDate={formattedDate}
                  imagen={require('../images/poster-bg-2.png')}
                />
              </div>
            </Slider>
          </div>

          <div className="container-text-cat-mobile">
            <h1 className="title-cat-mobile-left">Categorías</h1>
            <h1 className="title-cat-mobile-right" onClick={handleActiveAside}>Ver Todo</h1>
          </div>

          <div className="container-text-cat-mobile">
      <div className="container-row-flex-cat-pr" ref={categoriasContainerRef} style={{ overflowX: 'auto', whiteSpace: 'nowrap' }}>
        {/* Renderizar la categoría seleccionada al inicio */}
        <p
          className={`item-cat--pr ${selectedCategory === 'Todos los artículos' ? 'selected-tab-pr-cat' : ''}`}
          onClick={() => handleCategoryClick('Todos los artículos')}
          data-categoria="Todos los artículos"
        >
          Todos los productos ({productos.length})
        </p>

        {categorias.map((categoria) => (
          <p
            key={categoria.id}
            className={`item-cat--pr ${selectedCategory === categoria.nombre ? 'selected-tab-pr-cat' : ''}`}
            onClick={() => handleCategoryClick(categoria.nombre)}
            data-categoria={categoria.nombre}
          >
            {categoria.nombre} ({productos.filter((producto) => producto.categoria === categoria.nombre).length})
          </p>
        ))}
      </div>
    </div>

          <div className="container container-fluid-mobile span-pr-margin-mobile">
            <div className="row">
              {loading
                ? Array(6)
                    .fill()
                    .map((_, index) => (
                      <div className="col-12 card-products" key={index}>
                        <Skeleton height={300} className="skeleton-animated" />
                        <Skeleton
                          height={40}
                          className="skeleton-animated top-sekeleton"
                        />
                      </div>
                    ))
                : filteredProducts.map((producto) => (
                    <ProductoArt
                      key={producto.id}
                      producto={producto}
                      onProductoClick={() => onProductoClick(producto.id)}
                    />
                  ))}
            </div>
          </div>
        </div>
      </motion.div>
    </Fragment>
  );
}

export default Productos;
