import React, { Fragment, useState } from 'react';
import TabDash from '../comp/tabDash';
import '../styles/dashboard.css';
import { Routes, Route, NavLink } from 'react-router-dom';
import ProductosComp from '../comp/productosComp';
import VentasComp from '../comp/ventasComp';
import EstadisticasComp from '../comp/estadisticasComp';
import DashboardComp from '../comp/dashboardComp';
import BannerDash from '../comp/bannerDash';
import Slider from 'react-slick';
import CardDashComp from '../comp/cardDashComp';
import HeaderMobile from '../comp/headerMobile';
import SuscripcionesComp from '../comp/susbsComp';
import ConfigComp from '../comp/configComp';
import FinanzasComp from '../comp/finanzasComp';

function Dashboard() {
  const [date, setDate] = useState(new Date());
  const options = { day: 'numeric', month: 'numeric' };
  const formattedDate = date.toLocaleDateString(undefined, options);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: 1500,
  };

  return (
    <Fragment>
      <HeaderMobile />

      <div className="container-web-viewport">
        <div className="container container-general-dash">
          <div className="row">
            <div className="col-md-3 col-lg-3 col-xl-2">
              <TabDash />
            </div>
            <div className="col-md-9 col-lg-9 col-xl-10 canvas-dashboard-web">
              <Routes>
                <Route
                  path="/*"
                  element={<DashboardComp formattedDate={formattedDate} />}
                />
                <Route
                  path="productos"
                  element={<ProductosComp formattedDate={formattedDate} />}
                />
                <Route
                  path="ventas"
                  element={<VentasComp formattedDate={formattedDate} />}
                />
                <Route
                  path="suscripciones"
                  element={<SuscripcionesComp formattedDate={formattedDate} />}
                />
                <Route
                  path="configuraciones"
                  element={<ConfigComp formattedDate={formattedDate} />}
                />
                <Route
                  path="finanzas"
                  element={<FinanzasComp formattedDate={formattedDate} />}
                />
              </Routes>
            </div>
          </div>
        </div>
      </div>

      <div className="container-mobile-viewport">
        <div>
          <Slider {...settings}>
            <div>
              <BannerDash
                title="Bienvenido a tu dashboard"
                formattedDate={formattedDate}
                text="Dashboard"
              />
            </div>
            <div>
              <BannerDash
                title="Administra tus productos"
                formattedDate={formattedDate}
                text="Dashboard"
              />
            </div>
          </Slider>
        </div>

        <h1 className="title-dash-sec">Secciones de tu dashboard</h1>

        <div className="container-fluid">
          <div className="row">
            <div className="col-xl-4">
              <NavLink to="/dashboard/listado-productos" className="card-link">
                <CardDashComp title="Listado de productos" />
              </NavLink>
            </div>
            <div className="col-xl-4">
              <NavLink to="/dashboard/listado-ventas" className="card-link">
                <CardDashComp title="Mis ventas" />
              </NavLink>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default Dashboard;
