import React, { Fragment } from 'react';
import '../styles/cardDashComp.css';

function CardDashComp({ title }) {
  return (
    <Fragment>
      <div className="card-container-sec">
        <div className="container-decor-top">
          <h1 className="log-mark">X menos mas prendas</h1>
        </div>
        <div className="card-inide-pad">
          <p className="text-top-sec">Sección</p>
          <p className="text-top-sec-title"> {title} </p>
        </div>
      </div>
    </Fragment>
  );
}

export default CardDashComp;
