import React, { Fragment } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import '../styles/headerMobile.css';

function HeaderMobile() {
  const navigate = useNavigate();
  const location = useLocation();

  const isDashboard = location.pathname.startsWith('/dashboard/*');

  return (
    <Fragment>
      <div className="header-mobile">
        <h1 className="title-header-mobile">Dashboard Mobile</h1>
        {!isDashboard && (
          <div
            className="card-cart-products span-dash"
            onClick={() => navigate('/dashboard/*')}
          >
            <img
              className="img-card-cart-products"
              src={require('../images/atras.png')}
              alt="back"
            />
          </div>
        )}
      </div>
    </Fragment>
  );
}

export default HeaderMobile;
