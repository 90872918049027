import React, {useState} from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import '../styles/nosotros.css';
import { Pagination, Navigation } from 'swiper/modules';
import Footer from '../comp/footer';
import { useNavigate } from 'react-router-dom';
import Carrito from '../comp/carrito';
import HeaderMobileHome from '../comp/headerMobileHome';


const Nosotros = () => {
  const [activeCartShow, setActiveCartShow] = useState(false);
  const [activeAside, setActiveAside] = useState(false);

  
  const handleActiveCart = () => {
    setActiveCartShow(true);
  };

  const handleCloseCart = () => {
    setActiveCartShow(false);
  };

  const navigate = useNavigate();
  
  const handleActiveAside = () => {
    setActiveAside(true);
  };

  const handleCloseAside = () => {
    setActiveAside(false);
  };


  return (
    
    <div className="nosotros-container">

<Carrito
          activeCart={activeCartShow}
          handleCloseCart={handleCloseCart}
        />
         <HeaderMobileHome
          handleActiveCart={handleActiveCart}
          activeAside={activeAside}
          handleActiveAside={handleActiveAside}
          handleCloseAside={handleCloseAside}
        />
<div className="header-top-banner-products">
          <h1 className="text-inside-banner-products">
            El envío es gratis a partir de tu compra de 12 unidades en artículos
          </h1>
        </div>

        <header className="header-general-products">
          <img
            className="img-ptofile-brand"
            src={require('../images/logo.png')}
            onClick={() => navigate('/')}
            alt="logo"
          />

          <input
            className="input-header-search-products"
            type="text"
            placeholder="Buscar productos"
          />

          <div className="container-row-header-options">
            <li
              className="option-header"
              onClick={() => navigate('/productos')}
            >
              Articulos
            </li>
            <li
              className="option-header"
              onClick={() => navigate('/productos')}
            >
              Categorías
            </li>
            <li
              className="option-header"
              onClick={() => navigate('/compras-mayoristas')}
            >
              Compras Mayoristas
            </li>
          </div>

          <div className="card-cart-products" onClick={handleActiveCart}>
            <img
              className="img-card-cart-products"
              src={require('../images/cart.png')}
              alt="cart"
            />
          </div>
        </header>


      {/* Banner */}
      <section className="nosotros-banner">
        <div className="banner-content">
          <h1 className="banner-title">Conócenos</h1>
          <p className="banner-subtitle">Nuestra misión, visión y valores</p>
        </div>
      </section>

      {/* Sección de Información */}
      <section className="nosotros-info">
        <div className="container">
          <h2 className="info-title">¿Quiénes Somos?</h2>
          <p className="info-description">
          Con más de 10 años de experiencia, ofrecemos ropa y artículos variados a precios mayoristas, combinando calidad y estilo al mejor costo. Nuestra misión es que lleves más y gastes menos, siempre.
          </p>
       
        </div>
      </section>

      {/* Galería de Fotos con Swiper */}
      <section className="nosotros-gallery">
        <div className="container">
          <h2 className="gallery-title">Nuestra Galería</h2>
          <Swiper
            modules={[Pagination, Navigation]}
            spaceBetween={30}
            slidesPerView={1}
            loop={true}
            pagination={{ clickable: true }}
            navigation={true}
            className="swiper-container"
          >
            <SwiperSlide>
              <img src={require('../images/10.jpg')} alt="Galería 1" className="gallery-item" />
            </SwiperSlide>
            <SwiperSlide>
              <img src={require('../images/9.jpg')} alt="Galería 2" className="gallery-item" />
            </SwiperSlide>
            <SwiperSlide>
              <img src={require('../images/8.jpg')} alt="Galería 3" className="gallery-item" />
            </SwiperSlide>
            <SwiperSlide>
              <img src={require('../images/7.jpg')} alt="Galería 4" className="gallery-item" />
            </SwiperSlide>
          </Swiper>
        </div>
      </section>

      {/* Footer */}
      <Footer />
    </div>
  );
};

export default Nosotros;
