import React, { Fragment, useEffect, useState, useRef } from 'react';
import Slider from 'react-slick';
import BannerDash from './bannerDash';
import DashboardSalesDetails from './dashboardSalesDetails';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import axios from 'axios';
import { Button } from 'react-bootstrap';
import ModalAddVenta from './modalAddVenta';
import { motion } from 'framer-motion';
import HeaderMobile from './headerMobile';
import { RingLoader } from 'react-spinners';

function VentasComp({ formattedDate }) {
  const [ventas, setVentas] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedSale, setSelectedSale] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [formData, setFormData] = useState({});
  const [searchTerm, setSearchTerm] = useState('');
  const detailsRef = useRef(null);
  const [showModal, setShowModal] = useState(false);
  const [fechaSeleccionada, setFechaSeleccionada] = useState('');
  const [ventasFiltradas, setVentasFiltradas] = useState([]);
  const [filteredVentas, setFilteredVentas] = useState([]);
  const [ventasPorEstado, setVentasPorEstado] = useState({
    canceladas: 0,
    enCurso: 0,
    finalizadas: 0,
  });

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: 1500,
  };

  const handleClose = () => setShowModal(false);

  const handleShow = () => setShowModal(true);

  const handleShowBanner = (message) => alert(message);

  useEffect(() => {
    const fetchVentas = async () => {
      try {
        const response = await axios.get(
          'https://ecommerce-backend-self-phi.vercel.app/ventas',
        );
        const ventasData = response.data;
        setVentas(ventasData);
       setVentasFiltradas(ventasData);
       setFilteredVentas(ventasData);

        const estados = {
          canceladas: ventasData.filter(venta => venta.estado === 'cancelada').length,
          enCurso: ventasData.filter(venta => venta.estado === 'en curso').length,
          finalizadas: ventasData.filter(venta => venta.estado === 'finalizada').length,
        };
        setVentasPorEstado(estados);
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchVentas();
  }, []);


  useEffect(() => {
    const lowerCaseSearchTerm = searchTerm.toLowerCase();
    const filtered = ventas.filter((venta) => {
      const allFields = [
        venta.id,
        venta.nombre,
        venta.apellido,
        venta.direccion,
        venta.codigoPostal,
        venta.ciudad,
        venta.provincia,
        venta.celular,
        venta.correo,
        venta.estado,
        venta.total.toString(),
        new Date(venta.fechaHora).toLocaleString(),
      ]
        .join(' ')
        .toLowerCase();

      return allFields.includes(lowerCaseSearchTerm);
    });

    setFilteredVentas(filtered);
  }, [searchTerm, ventas]);

  const handleSaleClick = (venta) => {
    setSelectedSale(venta);
    setFormData(venta);
    setTimeout(() => {
      detailsRef.current.scrollIntoView({ behavior: 'smooth' });
    }, 100);
  };

  const handleEditChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleUpdateSale = async (e) => {
    e.preventDefault();
    try {
      await axios.put(
        `https://ecommerce-backend-self-phi.vercel.app/ventas/${selectedSale.id}`,
        formData,
      );
      const response = await axios.get(
        'https://ecommerce-backend-self-phi.vercel.app/ventas',
      );
      const ventasData = response.data;
      setVentas(ventasData);
      setVentasFiltradas(ventasData);

      const estados = {
        canceladas: ventasData.filter(venta => venta.estado === 'cancelada').length,
        enCurso: ventasData.filter(venta => venta.estado === 'en curso').length,
        finalizadas: ventasData.filter(venta => venta.estado === 'finalizada').length,
      };
      setVentasPorEstado(estados);

      setIsEditing(false);
      setSelectedSale(null);
    } catch (error) {
      setError(error.message);
    }
  };

  const handleDeleteSale = async () => {
    try {
      await axios.delete(
        `https://ecommerce-backend-self-phi.vercel.app/ventas/${selectedSale.id}`,
      );
      const response = await axios.get(
        'https://ecommerce-backend-self-phi.vercel.app/ventas',
      );
      const ventasData = response.data;
      setVentas(ventasData);

      // Actualizar ventas por estado
      const estados = {
        canceladas: ventasData.filter(venta => venta.estado === 'Cancelada').length,
        enCurso: ventasData.filter(venta => venta.estado === 'En curso').length,
        finalizadas: ventasData.filter(venta => venta.estado === 'Finalizado').length,
      };
      setVentasPorEstado(estados);

      setSelectedSale(null);
    } catch (error) {
      setError(error.message);
    }
  };

  const handleCloseDetails = () => {
    setSelectedSale(null);
  };


  const filtrarVentasPorFecha = () => {
    const ahora = new Date(); 
    const ventas24h = ventas.filter((venta) => {
      const fechaVenta = new Date(venta.fechaHora); 
      const diferencia = ahora - fechaVenta; 
      return diferencia <= 24 * 60 * 60 * 1000; 
    });
    setVentasFiltradas(ventas24h); 
  };

  const mostrarTodasLasVentas = () => {
    setVentasFiltradas(ventas); 
    console.log(ventas);
  };

  const filtrarVentasPorEstado = (estado) => {
    const ventasFiltradas = ventas.filter(venta => venta.estado === estado);
    setVentasFiltradas(ventasFiltradas); 
  };

  return (
    <Fragment>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.15 }}
      >
        <ModalAddVenta
          show={showModal}
          handleClose={handleClose}
          handleShowBanner={handleShowBanner}
        />

        <HeaderMobile />

        <div className="container-web-viewport">
          <div>
            <Slider {...settings}>
              <div>
                <BannerDash
                  title="Registro de ventas"
                  formattedDate={formattedDate}
                  text="Dashboard"
                />
              </div>
              <div>
                <BannerDash
                  title="Administralo"
                  formattedDate={formattedDate}
                  text="Dashboard"
                />
              </div>
            </Slider>
          </div>

          <div className='container-fluid'>
            <div className='row'>
              <div className='col-xl-4 col-lg-4 col-md-6'>
                <div className='card-col-ctn' onClick={() => filtrarVentasPorEstado('cancelada')}>
                  <p className='text-card-col-ctn'>Canceladas</p>
                  <h6 className='text-ctn-total-col'>
                    {loading ? <RingLoader size={30} color={"#000"} /> : ventasPorEstado.canceladas}
                  </h6>
                </div>
              </div>
              <div className='col-xl-4 col-lg-4 col-md-6'>
                <div className='card-col-ctn' onClick={() => filtrarVentasPorEstado('en curso')}>
                  <p className='text-card-col-ctn'>En curso</p>
                  <h6 className='text-ctn-total-col'>
                    {loading ? <RingLoader size={30} color={"#000"} /> : ventasPorEstado.enCurso}
                  </h6>
                </div>
              </div>
              <div className='col-xl-4 col-lg-4 col-md-6'>
                <div className='card-col-ctn' onClick={() => filtrarVentasPorEstado('finalizada')}>
                  <p className='text-card-col-ctn'>Finalizadas</p>
                  <h6 className='text-ctn-total-col'>
                    {loading ? <RingLoader size={30} color={"#000"} /> : ventasPorEstado.finalizadas}
                  </h6>
                </div>
              </div>
            </div>
          </div>

          <div className="container-table">
            <div className="header-actions">
              <input
                type="text"
                placeholder="Buscar ventas..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                className="search-input-ventas-comp"
              />
              <Button className="btn btn-secondary" onClick={handleShow}>
                Agregar Venta
              </Button>
              <button onClick={filtrarVentasPorFecha} className="btn btn-secondary btn-vtn-dash">Ventas Actuales</button>
              <button onClick={mostrarTodasLasVentas} className="btn btn-secondary btn-vtn-dash">Todas las Ventas</button>
            </div>

            <table className="table">
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Nombre</th>
                  <th>Apellido</th>
                  <th>Dirección</th>
                  <th>CP</th>
                  <th>Ciudad</th>
                  <th>Provincia</th>
                  <th>Celular</th>
                  <th>Correo</th>
                  <th>Fecha y Hora</th>
                  <th>Estado</th>
                  <th>Total</th>
                </tr>
              </thead>
              <tbody>
                {loading
                  ? Array(10)
                      .fill()
                      .map((_, index) => (
                        <tr key={index}>
                          <td>
                            <Skeleton width={30} />
                          </td>
                          <td>
                            <Skeleton width={100} />
                          </td>
                          <td>
                            <Skeleton width={100} />
                          </td>
                          <td>
                            <Skeleton width={150} />
                          </td>
                          <td>
                            <Skeleton width={60} />
                          </td>
                          <td>
                            <Skeleton width={80} />
                          </td>
                        </tr>
                      ))
                  : ventasFiltradas.map((venta) => (
                      <tr key={venta.id} onClick={() => handleSaleClick(venta)}>
                        <td>{venta.id}</td>
                        <td>{venta.nombre}</td>
                        <td>{venta.apellido}</td>
                        <td>{venta.direccion}</td>
                        <td>{venta.codigoPostal}</td>
                        <td>{venta.ciudad}</td>
                        <td>{venta.provincia}</td>
                        <td>{venta.celular}</td>
                        <td>{venta.correo}</td>
                        <td>{new Date(venta.fechaHora).toLocaleString()}</td>
                        <td>{venta.estado}</td>
                        <td>${venta.total.toLocaleString()}</td>
                      </tr>
                    ))}
              </tbody>
            </table>
          </div>

          {selectedSale && (
            <div ref={detailsRef} className="sale-details-container">
              <DashboardSalesDetails
                sale={selectedSale}
                formData={formData}
                isEditing={isEditing}
                onClose={handleCloseDetails}
                onEditChange={handleEditChange}
                onUpdate={handleUpdateSale}
                onDelete={handleDeleteSale}
                onEditToggle={() => setIsEditing((prev) => !prev)}
              />
            </div>
          )}

          {error && <p>Error: {error}</p>}
        </div>

        <div className="container-mobile-viewport">
          <div>
            <Slider {...settings}>
              <div>
                <BannerDash
                  title="Registro de ventas"
                  formattedDate={formattedDate}
                  text="Dashboard"
                />
              </div>
              <div>
                <BannerDash
                  title="Administralo"
                  formattedDate={formattedDate}
                  text="Dashboard"
                />
              </div>
            </Slider>
          </div>


          <div className='container-fluid'>
            <div className='row'>
              <div className='col-xl-4 col-lg-4 col-md-6'>
                <div className='card-col-ctn'>
                  <p className='text-card-col-ctn'>Canceladas</p>
                  <h6 className='text-ctn-total-col'>{ventasPorEstado.canceladas}</h6>
                </div>
              </div>
              <div className='col-xl-4 col-lg-4 col-md-6'>
                <div className='card-col-ctn'>
                  <p className='text-card-col-ctn'>En curso</p>
                  <h6 className='text-ctn-total-col'>{ventasPorEstado.enCurso}</h6>
                </div>
              </div>
              <div className='col-xl-4 col-lg-4 col-md-6'>
                <div className='card-col-ctn'>
                  <p className='text-card-col-ctn'>Finalizadas</p>
                  <h6 className='text-ctn-total-col'>{ventasPorEstado.finalizadas}</h6>
                </div>
              </div>
            </div>
          </div>

          <div className="sales-list-mobile" style={{ padding: '20px' }}>
            <Button
              className="btn btn-primary btn-add-sale-mobile"
              onClick={handleShow}
            >
              Agregar Venta
            </Button>
            {loading ? (
              <Skeleton count={5} />
            ) : (
              filteredVentas.map((venta) => (
                <div
                  key={venta.id}
                  className="sale-item"
                  onClick={() => handleSaleClick(venta)}
                  style={{ cursor: 'pointer', marginBottom: '10px' }}
                >
                  {venta.id}
                </div>
              ))
            )}
          </div>

          {selectedSale && (
            <div className="sale-details-container">
              <DashboardSalesDetails
                sale={selectedSale}
                formData={formData}
                isEditing={isEditing}
                onClose={handleCloseDetails}
                onEditChange={handleEditChange}
                onUpdate={handleUpdateSale}
                onDelete={handleDeleteSale}
                onEditToggle={() => setIsEditing((prev) => !prev)}
              />
            </div>
          )}
        </div>
      </motion.div>
    </Fragment>
  );
}

export default VentasComp;
