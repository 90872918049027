import React, { Fragment, useEffect, useState, useRef } from 'react';
import Slider from 'react-slick';
import BannerDash from './bannerDash';
import DashboardProductDetails from './dashboardProductDetails';
import ModalAddProducto from './modalAddProducto';
import Skeleton from 'react-loading-skeleton';
import { Modal, Button } from 'react-bootstrap';
import 'react-loading-skeleton/dist/skeleton.css';
import { motion } from 'framer-motion';
import HeaderMobile from './headerMobile';

const truncateText = (text, maxLength) => {
  return text.length > maxLength ? text.substring(0, maxLength) + '...' : text;
};

const renderPuntos = (items) => {
  if (!items || !Array.isArray(items)) {
    return <span>Sin datos</span>; 
  }

  return items.map((item, index) => (
    <span
      key={index}
      style={{
        display: 'inline-block',
        width: '2px',
        height: '2px',
        borderRadius: '50%',
        backgroundColor: item.color || 'black',
        marginRight: '5px',
      }}
      title={item.color || item.talle}
    ></span>
  ));
};


function ProductosComp({ formattedDate }) {
  const [productos, setProductos] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const detailsRef = useRef(null);
  const [selectedCategory, setSelectedCategory] = useState('');
  const [showInactiveProducts, setShowInactiveProducts] = useState(false);
  const [showActiveProducts, setShowActiveProducts] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');


  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: 1500,
  };

  useEffect(() => {
    const fetchProductos = async () => {
      try {
        const response = await fetch(
          'https://ecommerce-backend-self-phi.vercel.app/productos',
        );
        if (!response.ok) {
          throw new Error('Error al obtener los productos');
        }
        const data = await response.json();
        setProductos(data);
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchProductos();
  }, []);

  const handleProductClick = (producto) => {
    setSelectedProduct(producto);
    setTimeout(() => {
      detailsRef.current.scrollIntoView({ behavior: 'smooth' });
    }, 100);
  };

  const handleCloseDetails = () => {
    setSelectedProduct(null);
  };

  const handleDeleteProduct = () => {
    setSelectedProduct(null);
    fetch('https://ecommerce-backend-self-phi.vercel.app/productos')
      .then((response) => response.json())
      .then((data) => setProductos(data))
      .catch((error) => setError(error.message));
  };

  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);

  const handleAddProduct = (newProduct) => {
    setProductos([...productos, newProduct]);
    handleCloseModal();
  };


  const handleCategoryClick = (category) => {
    setSelectedCategory(category);
  };

  const filteredProductos = (showInactiveProducts
    ? productos.filter((producto) => producto.status === false)
    : showActiveProducts
    ? productos.filter((producto) => producto.status === true)
    : selectedCategory
    ? productos.filter((producto) =>
        producto.nombre.toLowerCase().includes(selectedCategory.toLowerCase())
      )
    : productos
  ).filter((producto) => {
    const allFields = [
      producto.id,
      producto.nombre,
      producto.descripcion,
      producto.precio.toString(),
      producto.stockGeneral.toString(),
    ]
      .join(' ')
      .toLowerCase();
  
    return allFields.includes(searchTerm.toLowerCase());
  });
  

    const inactiveProductsCount = productos.filter((producto) => producto.status === false).length;

    const activeProductsCount = productos.filter((producto) => producto.status === true).length;

  return (
    <Fragment>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.15 }}
      >
        <HeaderMobile />

        <div className="container-web-viewport">
          <div>
            <Slider {...settings}>
              <div>
                <BannerDash
                  title="Listado de productos"
                  text="Dashboard"
                  formattedDate={formattedDate}
                />
              </div>
              <div>
                <BannerDash
                  title="Administralo"
                  text="Dashboard"
                  formattedDate={formattedDate}
                />
              </div>
            </Slider>
          </div>


          <div className='container-fluid'>
            <div className='row'>
              <div className='col-xl-4 col-lg-4 col-md-6'>
                <div className='card-col-ctn'>
                  <p className='text-card-col-ctn'>Productos</p>
                  <h6 className='text-ctn-total-col'>{productos.length}</h6>
                </div>
              </div>
              
              <div className='col-xl-4 col-lg-4 col-md-6'  onClick={() => setShowInactiveProducts(true)}
  style={{ cursor: 'pointer' }}>
                <div className='card-col-ctn'>
                  <p className='text-card-col-ctn'>Inactivos</p>
                  <h6 className='text-ctn-total-col'>{inactiveProductsCount}</h6>
                </div>
              </div>

              <div className='col-xl-4 col-lg-4 col-md-6'  onClick={() => setShowActiveProducts(true)}
  style={{ cursor: 'pointer' }}>
                <div className='card-col-ctn'>
                  <p className='text-card-col-ctn'>Activos</p>
                  <h6 className='text-ctn-total-col'>{activeProductsCount}</h6>
                </div>
              </div>

            </div>
          </div>

          <div className="container-table">
            <table className="table">
              <thead>
                <tr>
                  <th colSpan="9">
                    <div className="button-container">
                    <Button className="btn btn-secondary btn-cat-dash" onClick={() => setShowInactiveProducts(false)}>
                    Mostrar Todos los Productos
            </Button>
                    <Button className="btn btn-secondary btn-cat-dash" onClick={() => handleCategoryClick('buzo')}>
              Buzos
            </Button>
            <Button className="btn btn-secondary btn-cat-dash" onClick={() => handleCategoryClick('campera')}>
              Camperas
            </Button>
            <Button className="btn btn-secondary btn-cat-dash" onClick={() => handleCategoryClick('remera')}>
              Remeras
            </Button>
            <Button className="btn btn-secondary btn-cat-dash" onClick={() => handleCategoryClick('zapatilla')}>
              Zapatillas
            </Button>
           
                      <Button
                        className="btn btn-secondary"
                        onClick={handleShowModal}
                      >
                        Agregar Producto
                      </Button>
                      <input
                        className="input-pr-dash"
                        type="text"
                        placeholder="Buscar Productos"
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)} 
                      />
                    </div>
                  </th>
                </tr>
                <tr>
                  <th>ID</th>
                  <th>Imagen</th>
                  <th>Nombre</th>
                  <th>Colores</th>
                  <th>Talles</th>
                  <th>Precio</th>
                  <th>Stock General</th>
                  <th>Descripción</th>
                </tr>
              </thead>
              <tbody>
                {loading
                  ? Array(10)
                      .fill()
                      .map((_, index) => (
                        <tr key={index}>
                          <td>
                            <Skeleton width={30} />
                          </td>
                          <td>
                            <Skeleton width={50} height={50} />
                          </td>
                          <td>
                            <Skeleton width={100} />
                          </td>
                          <td>
                            <Skeleton width={60} />
                          </td>
                          <td>
                            <Skeleton width={50} />
                          </td>
                          <td>
                            <Skeleton width={50} />
                          </td>
                          <td>
                            <Skeleton width={150} />
                          </td>
                        </tr>
                      ))
                  : filteredProductos.map((producto) => (
                      <tr
                        key={producto.id}
                        onClick={() => handleProductClick(producto)}
                      >
                        <td>{truncateText(producto.id, 7)}</td>
                        <td>
                          <img
                            src={producto.imagenes[0]}
                            alt={producto.nombre}
                            width="35"
                          />
                        </td>
                        <td>{truncateText(producto.nombre, 7)}</td>
                        <td>{renderPuntos(producto.colores)}...</td>
                        <td>{renderPuntos(producto.talles)}...</td>
                        <td>{truncateText(producto.precio.toString(), 7)}</td>
                        <td>
                         {producto.stockGeneral !== undefined && producto.stockGeneral !== null
              ? truncateText(producto.stockGeneral.toString(), 7)
              : 'Sin stock'}
                        </td>
                        <td>
                          {truncateText(
                            producto.descripcion.length > 7
                              ? producto.descripcion
                              : producto.descripcion,
                            7,
                          )}
                        </td>
                      </tr>
                    ))}
              </tbody>
            </table>
          </div>

          {selectedProduct && (
            <div ref={detailsRef} className="product-details-container">
              <DashboardProductDetails
                product={selectedProduct}
                onClose={handleCloseDetails}
                onDelete={handleDeleteProduct}
              />
            </div>
          )}

          {error && <p>Error: {error}</p>}

          <Modal show={showModal} onHide={handleCloseModal} size="lg">
            <Modal.Header closeButton>
              <Modal.Title>Agregar Producto</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <ModalAddProducto onAdd={handleAddProduct} />
            </Modal.Body>
          </Modal>
        </div>

        <div className="container-mobile-viewport">
          <div>
            <Slider {...settings}>
              <div>
                <BannerDash
                  title="Listado de productos"
                  text="Dashboard"
                  formattedDate={formattedDate}
                />
              </div>
              <div>
                <BannerDash
                  title="Administralo"
                  text="Dashboard"
                  formattedDate={formattedDate}
                />
              </div>
            </Slider>
          </div>

          <div className='container-fluid'>
            <div className='row'>
              <div className='col-xl-4 col-lg-4 col-md-6'>
                <div className='card-col-ctn'>
                  <p className='text-card-col-ctn'>Productos</p>
                  <h6 className='text-ctn-total-col'>{productos.length}</h6>
                </div>
              </div>
            </div>
          </div>

          <div className="product-list-mobile" style={{ padding: '20px' }}>
            <Button
              className="btn btn-primary btn-add-sale-mobile"
              onClick={handleShowModal}
            >
              Agregar Producto
            </Button>
            {loading ? (
              <Skeleton count={5} />
            ) : (
              productos.map((producto) => (
                <div
                  key={producto.id}
                  className="product-item"
                  onClick={() => handleProductClick(producto)}
                  style={{ cursor: 'pointer', marginBottom: '10px' }}
                >
                  {producto.id}
                </div>
              ))
            )}
          </div>

          {selectedProduct && (
            <div className="product-details-container">
              <DashboardProductDetails
                product={selectedProduct}
                onClose={handleCloseDetails}
                onDelete={handleDeleteProduct}
              />
            </div>
          )}
        </div>
      </motion.div>
    </Fragment>
  );
}

export default ProductosComp;
