import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import '../styles/carrito.css';

function Confirmacion() {
  const [ventaId, setVentaId] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const storedVentaId = localStorage.getItem('ventaId');
    if (storedVentaId) {
      setVentaId(storedVentaId);
    } else {
      console.error("ID de venta no encontrado");
    }
  }, []);

  const generarMensajeWhatsApp = () => {
    const numeroWhatsApp = '+5491131827270';
    const mensaje = `Hola, ¿cómo estás? Te dejo mi pedido con ID ${ventaId}. Compré por la web.`;

    return `https://wa.me/${numeroWhatsApp}?text=${encodeURIComponent(
      mensaje,
    )}`;
  };

  return (
    <div className="popup-compra-carrito">
      <div className="card-insiede-compra-carrito">
        <h3 className="title-confirm">Compra Exitosa, ID de la venta:</h3>
        <h6 className="text-id-confirm">{ventaId}</h6>
        <button
          className="btn-confirmacion"
          onClick={() => navigator.clipboard.writeText(ventaId)}
        >
          Copiar ID
        </button>
        <button
          className="btn-confirmacion"
          onClick={() => window.open(generarMensajeWhatsApp(), '_blank')}
        >
          Hablar por WhatsApp
        </button>
        <button className="btn-confirmacion" onClick={() => navigate(-1)}>
          Regresar
        </button>
      </div>
    </div>
  );
}

export default Confirmacion;
