import React from 'react';

function BannerPrWeb({ title, text, imagen }) {
  return (
    <div>
      <div className="container-canvas-dashboard-web">
        <div className="banner-dash">
          <h1 className="title-banner-container-text">{title}</h1>
          <div className="item-banner-flex">
            <img className='poster-banner-pr-web' src={imagen} alt="poster x menos mas prendas" />
          </div>
          <div>
            <h1 className="title-banner-span-copy"> {text} </h1>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BannerPrWeb;
