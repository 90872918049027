import React, { useState } from 'react';
import axios from 'axios';
import { useCarrito } from '../services/carritoController';
import '../styles/carrito.css';
import { useNavigate } from 'react-router-dom';
import emailjs from 'emailjs-com';

function Carrito({ activeCart, handleCloseCart }) {
  const { carrito, removerDelCarrito, sumarCantidad } = useCarrito();
  const [nombre, setNombre] = useState('');
  const [apellido, setApellido] = useState('');
  const [direccion, setDireccion] = useState('');
  const [codigoPostal, setCodigoPostal] = useState('');
  const [ciudad, setCiudad] = useState('');
  const [provincia, setProvincia] = useState('');
  const [celular, setCelular] = useState('');
  const [correo, setCorreo] = useState('');
  const [error, setError] = useState(null);
  const [ventaId, setVentaId] = useState(null);
  const [preferenceId, setPreferenceId] = useState(null);
  const navigate = useNavigate();


  const enviarCorreoConfirmacion = (ventaId, correo, nombre, apellido, fechaHora, items) => {

    emailjs.init('1TvGAqa8GJ5H2BDYG');
    const EMAILJS_SERVICE_ID = 'service_ddsf4da';
const EMAILJS_TEMPLATE_ID = 'template_3b1p6ra';
    const templateParams = {
      to_email: correo,
      from_name: nombre,
      from_surname: apellido,
      sale_id: ventaId,
      date_time: fechaHora,
      items: items.map(item => `${item.nombre} - ${item.cantidad}`).join(', ')
    };

    emailjs.send(EMAILJS_SERVICE_ID, EMAILJS_TEMPLATE_ID, templateParams)
      .then((response) => {
        console.log('Correo enviado con éxito:', response);
      }, (error) => {
        console.error('Error al enviar el correo:', error);
      });
  };


  const enviarPedido = async () => {
    if (!nombre || !apellido || !direccion || !codigoPostal || !ciudad || !provincia || !celular || !correo) {
      setError('Por favor, complete todos los campos.');
      return;
    }

    const fechaHora = new Date().toISOString();
    const pedido = {
      nombre,
      apellido,
      direccion,
      codigoPostal,
      ciudad,
      provincia,
      celular,
      correo,
      fechaHora,
      estado: 'en curso',
      productos: carrito.map((producto) => ({
        id: producto.id,
        nombre: producto.nombre,
        cantidad: producto.cantidad,
        color: producto.color,
        talle: producto.talle,
        precio: producto.precio,
      })),
      total: carrito.reduce(
        (total, producto) => total + producto.precio * producto.cantidad,
        0,
      ),
    };

    try {
      const response = await axios.post(
        'https://ecommerce-backend-self-phi.vercel.app/ventas',
        pedido,
      );
      const ventaGeneradaId = response.data.id;
      setVentaId(ventaGeneradaId);
      localStorage.setItem('ventaId', ventaGeneradaId);

      await enviarCorreoConfirmacion(
        ventaGeneradaId,
        correo,
        nombre,
        apellido,
        fechaHora,
        carrito
      );

      const preference = {
        items: carrito.map((producto) => ({
          title: producto.nombre,
          unit_price: producto.precio,
          quantity: producto.cantidad,
        })),
        payer: {
          name: nombre,
          surname: apellido,
          email: correo,
          phone: {
            area_code: '',
            number: celular,
          },
          address: {
            street_name: direccion,
            zip_code: codigoPostal,
            city: ciudad,
            state: provincia,
          },
        },
        back_urls: {
          success: `https://xmenosmasprendas.com/confirmacion`,
          failure: `http://localhost:3000/error`,
          pending: `http://localhost:3000/pendiente`,
        },
        auto_return: 'approved',
      };

      const mpResponse = await axios.post(
        'https://ecommerce-backend-self-phi.vercel.app/crear-preferencia',
        preference,
      );
      const preferenceId = mpResponse.data.id;
      setPreferenceId(preferenceId);



      // Redirigir a la página de confirmación de pago
      window.location.href = mpResponse.data.init_point;
    } catch (error) {
      console.error(
        'Error al enviar el pedido o crear la preferencia de pago:',
        error,
      );
      setError('Error al enviar el pedido. Por favor, intente nuevamente.');
    }
  };

  const handleRestarCantidad = (producto) => {
    if (producto.cantidad > 1) {
      sumarCantidad(producto.id, producto.color, producto.talle, -1);
    } else {
      removerDelCarrito(producto.id, producto.color, producto.talle);
    }
  };

  const handleSumarCantidad = (producto) => {
    sumarCantidad(producto.id, producto.color, producto.talle, 1);
  };

  const totalGeneral = carrito.reduce((total, producto) => {
    return total + producto.precio * producto.cantidad;
  }, 0);

  return (
    <div className={`carrito ${activeCart ? 'carrito-active' : ''}`}>
      <img
        className="img-close-cart"
        src={require('../images/close.png')}
        onClick={handleCloseCart}
        alt="cerrar"
      />
      <h2 className="title-cart-general">Carrito de Compras</h2>
      {carrito.length === 0 ? (
        <p>Tu carrito está vacío</p>
      ) : (
        <div>
          {carrito.map((producto) => (
            <div
              key={`${producto.id}-${producto.color}-${producto.talle}`}
              className="carrito-item"
            >
              <div className="carrito-info">
                <h3 className="title-name-pr">{producto.nombre}</h3>
                <p className="text-name-pr">Color: {producto.color}</p>
                {producto.talle && <p className="text-name-pr">Talle: {producto.talle}</p>}
                <p className="text-name-pr">
                  Precio: ${producto.precio.toLocaleString()}
                </p>
                <p className="text-name-pr">Cantidad: {producto.cantidad}</p>
                <p className="text-name-pr span-total">
                  Total: $
                  {(producto.precio * producto.cantidad).toLocaleString()}
                </p>
                <div className="btn-cart-cantidad">
                  <button
                    className="btn-cart-restar btn-cant"
                    onClick={() => handleRestarCantidad(producto)}
                  >
                    -
                  </button>
                  <button
                    className="btn-cart-sumar btn-cant"
                    onClick={() => handleSumarCantidad(producto)}
                  >
                    +
                  </button>
                </div>
                <button
                  className="btn-cart-delete"
                  onClick={() =>
                    removerDelCarrito(
                      producto.id,
                      producto.color,
                      producto.talle,
                    )
                  }
                >
                  Eliminar
                </button>
              </div>
            </div>
          ))}

          <div className="total-general">
            <h3 className="total-carrito-text">
              Total Carrito: ${totalGeneral.toLocaleString()}
            </h3>
          </div>

          <h2 className='text-title-form-user-data'>Datos de compra</h2>

          <div className="form-datos span-carrito-form">
            <input
              type="text"
              placeholder="Nombre"
              value={nombre}
              onChange={(e) => setNombre(e.target.value)}
            />
            <input
              type="text"
              placeholder="Apellido"
              value={apellido}
              onChange={(e) => setApellido(e.target.value)}
            />
            <input
              type="text"
              placeholder="Dirección"
              value={direccion}
              onChange={(e) => setDireccion(e.target.value)}
            />
            <input
              type="text"
              placeholder="Código Postal"
              value={codigoPostal}
              onChange={(e) => setCodigoPostal(e.target.value)}
            />
            <input
              type="text"
              placeholder="Ciudad"
              value={ciudad}
              onChange={(e) => setCiudad(e.target.value)}
            />
            <input
              type="text"
              placeholder="Provincia"
              value={provincia}
              onChange={(e) => setProvincia(e.target.value)}
            />
            <input
              type="text"
              placeholder="Celular"
              value={celular}
              onChange={(e) => setCelular(e.target.value)}
            />
            <input
              type="email"
              placeholder="Correo Electrónico"
              value={correo}
              onChange={(e) => setCorreo(e.target.value)}
            />
          </div>

          {error && <p className="error-text">{error}</p>}

          <button className="btn-finalizar-compra" onClick={enviarPedido}>
            Finalizar Compra
          </button>
        </div>
      )}
    </div>
  );
}

export default Carrito;
