import { createContext, useContext, useReducer } from 'react';

// Contexto para el carrito
const CarritoContext = createContext();

// Reductor para gestionar el estado del carrito
const carritoReducer = (state, action) => {
  switch (action.type) {
    case 'AGREGAR_AL_CARRITO':
      const productoExistente = state.find(
        (prod) =>
          prod.id === action.payload.id &&
          prod.color === action.payload.color &&
          (prod.talle === action.payload.talle || !action.payload.talle)
      );
      if (productoExistente) {
        return state.map((prod) =>
          prod.id === action.payload.id &&
          prod.color === action.payload.color &&
          (prod.talle === action.payload.talle || !action.payload.talle)
            ? { ...prod, cantidad: prod.cantidad + action.payload.cantidad }
            : prod,
        );
      } else {
        return [...state, action.payload];
      }
    case 'REMOVER_DEL_CARRITO':
      return state.filter(
        (prod) =>
          !(
            prod.id === action.payload.id &&
            prod.color === action.payload.color &&
            (prod.talle === action.payload.talle || !action.payload.talle)
          ),
      );
    case 'SUMAR_CANTIDAD':
      return state.map((prod) =>
        prod.id === action.payload.id &&
        prod.color === action.payload.color &&
        (prod.talle === action.payload.talle || !action.payload.talle)
          ? {
              ...prod,
              cantidad: Math.max(prod.cantidad + action.payload.cantidad, 1),
            }
          : prod,
      );
    case 'RESTAR_CANTIDAD':
      return state.map((prod) =>
        prod.id === action.payload.id &&
        prod.color === action.payload.color &&
        (prod.talle === action.payload.talle || !action.payload.talle)
          ? {
              ...prod,
              cantidad: Math.max(prod.cantidad - action.payload.cantidad, 1),
            }
          : prod,
      );
    default:
      return state;
  }
};

// Proveedor del contexto del carrito
export const CarritoProvider = ({ children }) => {
  const [carrito, dispatch] = useReducer(carritoReducer, []);

  // Función para agregar un producto al carrito
  const agregarAlCarrito = (producto) => {
    dispatch({ type: 'AGREGAR_AL_CARRITO', payload: producto });
  };

  // Función para remover un producto del carrito
  const removerDelCarrito = (id, color, talle) => {
    dispatch({ type: 'REMOVER_DEL_CARRITO', payload: { id, color, talle } });
  };

  // Función para sumar cantidad de un producto en el carrito
  const sumarCantidad = (id, color, talle, cantidad) => {
    dispatch({
      type: 'SUMAR_CANTIDAD',
      payload: { id, color, talle, cantidad },
    });
  };

  // Función para restar cantidad de un producto en el carrito
  const restarCantidad = (id, color, talle, cantidad) => {
    dispatch({
      type: 'RESTAR_CANTIDAD',
      payload: { id, color, talle, cantidad },
    });
  };

  return (
    <CarritoContext.Provider
      value={{
        carrito,
        agregarAlCarrito,
        removerDelCarrito,
        sumarCantidad,
        restarCantidad,
      }}
    >
      {children}
    </CarritoContext.Provider>
  );
};

// Hook para usar el contexto del carrito
export const useCarrito = () => useContext(CarritoContext);
