import React from 'react';

function ProductoArt({ producto, onProductoClick }) {
  return (
    <div
      className="col-6 col-lg-3 col-md-6 card-products"
      onClick={() => onProductoClick(producto.id)}
    >
      <div className="container-img-relative-things">
        <h2 className="banner-tag-container-img">Destacado</h2>
        <img
          className="img-products-section-article"
          src={producto.imagenes[0]}
          alt={producto.nombre}
        />
        <div
          className="card-cart-products span-item-card-product"
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <img
            className="img-card-cart-products"
            src={require('../images/cart.png')}
            alt="cart"
          />
        </div>
      </div>
      <p className="text-tag-pr">x menos más prendas</p>
      <h1 className="title-product-section-aticle">{producto.nombre}</h1>
      <p className="text-price-pr"> Precio: ${producto.precio} </p>
    </div>
  );
}

export default ProductoArt;
