import React, { useState, useEffect } from 'react';
import axios from 'axios';
import BannerDash from './bannerDash';
import CardDashComp from './cardDashComp';

const ConfigComp = ({ formattedDate }) => {
  const [texto, setTexto] = useState('');
  const [texto2, setTexto2] = useState('');
  const [terminos, setTerminos] = useState('');
  const [nuevaPregunta, setNuevaPregunta] = useState('');
  const [nuevaRespuesta, setNuevaRespuesta] = useState('');
  const [preguntas, setPreguntas] = useState([]);
  const [editandoId, setEditandoId] = useState(null);
  const [editandoPregunta, setEditandoPregunta] = useState('');
  const [editandoRespuesta, setEditandoRespuesta] = useState('');
  const [nuevoTexto, setNuevoTexto] = useState('');
  const [nuevoTexto2, setNuevoTexto2] = useState('');
  const [nuevoTerminos, setNuevoTerminos] = useState('');
  const [loadingTextos, setLoadingTextos] = useState(false);
  const [loadingTerminos, setLoadingTerminos] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [activeSection, setActiveSection] = useState(null); 
  const [comprasMayoristas, setComprasMayoristas] = useState([]);
  const [nuevaCompra, setNuevaCompra] = useState({ nombre: '', imagen: '', whatsapp: '' });
  const [editandoCompra, setEditandoCompra] = useState(null);
  const [categorias, setCategorias] = useState([]);
  const [nuevaCategoria, setNuevaCategoria] = useState('');
  const [nuevasSubcategorias, setNuevasSubcategorias] = useState(['']);
  const [editandoCategoria, setEditandoCategoria] = useState(null);
  const [editandoSubcategoria, setEditandoSubcategoria] = useState(null);
  const [agregandoSubcategoriaId, setAgregandoSubcategoriaId] = useState(null);
  const [nuevaSubcategoria, setNuevaSubcategoria] = useState('');

  useEffect(() => {
    // Fetch config texts and FAQs on mount
    const fetchConfigData = async () => {
      try {
        const response = await axios.get('https://ecommerce-backend-self-phi.vercel.app/config');
        setTexto(response.data.texto);
        setTexto2(response.data.texto2);
        setTerminos(response.data.terminos);
      } catch (error) {
        console.error('Error al cargar los textos:', error);
      }
    };

    const fetchPreguntas = async () => {
      try {
        const response = await axios.get('https://ecommerce-backend-self-phi.vercel.app/faqs');
        setPreguntas(response.data);
      } catch (error) {
        console.error('Error al cargar preguntas frecuentes:', error);
      }
    };

    fetchConfigData();
    fetchPreguntas();
  }, []);

  // Handling for Textos Banner
  const handleChangeTextos = (e) => {
    if (e.target.id === 'texto') {
      setNuevoTexto(e.target.value);
    } else if (e.target.id === 'texto2') {
      setNuevoTexto2(e.target.value);
    }
  };

  const handleSubmitTextos = async (e) => {
    e.preventDefault();
    setLoadingTextos(true);

    const dataToSend = {};
    if (nuevoTexto !== texto && nuevoTexto) {
      dataToSend.texto = nuevoTexto;
    }
    if (nuevoTexto2 !== texto2 && nuevoTexto2) {
      dataToSend.texto2 = nuevoTexto2;
    }

    if (Object.keys(dataToSend).length === 0) {
      setSuccessMessage('No se han realizado cambios en los textos');
      setLoadingTextos(false);
      return;
    }

    try {
      await axios.put('https://ecommerce-backend-self-phi.vercel.app/config', dataToSend);
      setSuccessMessage('Textos actualizados correctamente');
      if (dataToSend.texto) setTexto(nuevoTexto);
      if (dataToSend.texto2) setTexto2(nuevoTexto2);
    } catch (error) {
      console.error('Error al actualizar los textos:', error);
      setSuccessMessage('Hubo un error al actualizar los textos');
    } finally {
      setLoadingTextos(false);
    }
  };

  // Handling for Términos y Condiciones
  const handleChangeTerminos = (e) => {
    setNuevoTerminos(e.target.value);
  };

  const handleSubmitTerminos = async (e) => {
    e.preventDefault();
    setLoadingTerminos(true);

    if (nuevoTerminos === terminos || !nuevoTerminos) {
      setSuccessMessage('No se han realizado cambios en los términos y condiciones');
      setLoadingTerminos(false);
      return;
    }

    const dataToSend = { terminos: nuevoTerminos };

    try {
      await axios.put('https://ecommerce-backend-self-phi.vercel.app/config', dataToSend);
      setSuccessMessage('Términos y condiciones actualizados correctamente');
      setTerminos(nuevoTerminos);
    } catch (error) {
      console.error('Error al actualizar los términos y condiciones:', error);
      setSuccessMessage('Hubo un error al actualizar los términos y condiciones');
    } finally {
      setLoadingTerminos(false);
    }
  };

  // Handling for Preguntas Frecuentes
  const handleAgregarPregunta = async (e) => {
    e.preventDefault();
    if (nuevaPregunta && nuevaRespuesta) {
      try {
        const response = await axios.post('https://ecommerce-backend-self-phi.vercel.app/faqs', {
          pregunta: nuevaPregunta,
          respuesta: nuevaRespuesta,
        });
        setPreguntas([...preguntas, response.data]);
        setNuevaPregunta('');
        setNuevaRespuesta('');
      } catch (error) {
        console.error('Error al agregar la pregunta frecuente:', error);
      }
    }
  };

  const handleEditarPregunta = async (id) => {
    if (editandoPregunta && editandoRespuesta) {
      try {
        const response = await axios.put(`https://ecommerce-backend-self-phi.vercel.app/faqs/${id}`, {
          pregunta: editandoPregunta,
          respuesta: editandoRespuesta,
        });
        const preguntasActualizadas = preguntas.map((pregunta) =>
          pregunta.id === id ? response.data : pregunta
        );
        setPreguntas(preguntasActualizadas);
        setEditandoId(null);
        setEditandoPregunta('');
        setEditandoRespuesta('');
      } catch (error) {
        console.error('Error al editar la pregunta frecuente:', error);
      }
    }
  };

  const handleEliminarPregunta = async (id) => {
    try {
      await axios.delete(`https://ecommerce-backend-self-phi.vercel.app/faqs/${id}`);
      setPreguntas(preguntas.filter((pregunta) => pregunta.id !== id));
    } catch (error) {
      console.error('Error al eliminar la pregunta frecuente:', error);
    }
  };


  useEffect(() => {
    const fetchComprasMayoristas = async () => {
      try {
        const response = await axios.get('https://ecommerce-backend-self-phi.vercel.app/compras-mayoristas');
        setComprasMayoristas(response.data);
      } catch (error) {
        console.error('Error fetching compras mayoristas:', error);
      }
    };
    
    fetchComprasMayoristas();
  }, []);

  
  const handleAgregarCompra = async (e) => {
    e.preventDefault(); 
    try {
      const response = await axios.post('https://ecommerce-backend-self-phi.vercel.app/compras-mayoristas', nuevaCompra);
      setComprasMayoristas([...comprasMayoristas, response.data]);
      setNuevaCompra({ nombre: '', imagen: '', whatsapp: '' });
      setSuccessMessage('Agente mayorista agregada correctamente');
    } catch (error) {
      console.error('Error adding compra mayorista:', error);
    }
  };

  const handleEditarCompra = async (id) => {
    try {
      await axios.put(`https://ecommerce-backend-self-phi.vercel.app/compras-mayoristas/${id}`, editandoCompra);
      setComprasMayoristas(comprasMayoristas.map((compra) =>
        compra.id === id ? { ...compra, ...editandoCompra } : compra
      ));
      setEditandoCompra(null);
      setSuccessMessage('Agente mayorista actualizada correctamente');
    } catch (error) {
      console.error('Error updating compra mayorista:', error);
    }
  };

  const handleEliminarCompra = async (id) => {
    try {
      await axios.delete(`https://ecommerce-backend-self-phi.vercel.app/compras-mayoristas/${id}`);
      setComprasMayoristas(comprasMayoristas.filter((compra) => compra.id !== id));
      setSuccessMessage('Agente mayorista eliminada correctamente');
    } catch (error) {
      console.error('Error deleting compra mayorista:', error);
    }
  };


  useEffect(() => {
    const fetchCategorias = async () => {
      try {
        const response = await axios.get('https://ecommerce-backend-self-phi.vercel.app/categorias');
        setCategorias(response.data);
      } catch (error) {
        console.error('Error al obtener las categorías:', error);
      }
    };
    fetchCategorias();
  }, []);

  const handleAgregarCategoria = async (e) => {
    e.preventDefault();
    if (nuevaCategoria) {
      try {
        const response = await axios.post('https://ecommerce-backend-self-phi.vercel.app/categorias', {
          nombre: nuevaCategoria,
          subcategorias: nuevasSubcategorias.filter(Boolean).map((nombre) => ({ nombre })),
        });
        setCategorias([...categorias, response.data]);
        setNuevaCategoria('');
        setNuevasSubcategorias(['']);
        setSuccessMessage('Categoría y subcategorías agregadas correctamente');
      } catch (error) {
        console.error('Error al agregar la categoría:', error);
      }
    }
  };

  const handleSubcategoriaChange = (index, value) => {
    const updatedSubcategorias = [...nuevasSubcategorias];
    updatedSubcategorias[index] = value;
    setNuevasSubcategorias(updatedSubcategorias);
  };

  const agregarCampoSubcategoria = () => {
    setNuevasSubcategorias([...nuevasSubcategorias, '']);
  };

  const eliminarCampoSubcategoria = (index) => {
    const updatedSubcategorias = nuevasSubcategorias.filter((_, i) => i !== index);
    setNuevasSubcategorias(updatedSubcategorias);
  };

  const iniciarEdicionCategoria = (categoria) => {
    setEditandoCategoria(categoria);
  };

  const handleGuardarCategoria = async () => {
    try {
      await axios.put(`https://ecommerce-backend-self-phi.vercel.app/categorias/${editandoCategoria.id}`, {
        nombre: editandoCategoria.nombre,
      });
      setCategorias(
        categorias.map((cat) =>
          cat.id === editandoCategoria.id ? editandoCategoria : cat
        )
      );
      setEditandoCategoria(null);
      setSuccessMessage('Categoría actualizada correctamente');
    } catch (error) {
      console.error('Error al actualizar la categoría:', error);
    }
  };

  const handleEliminarCategoria = async (id) => {
    try {
      await axios.delete(`https://ecommerce-backend-self-phi.vercel.app/categorias/${id}`);
      setCategorias(categorias.filter((categoria) => categoria.id !== id));
      setSuccessMessage('Categoría eliminada correctamente');
    } catch (error) {
      console.error('Error al eliminar la categoría:', error);
    }
  };

  const iniciarEdicionSubcategoria = (categoriaId, subcategoria) => {
    setEditandoSubcategoria({ ...subcategoria, categoriaId });
  };

  const handleGuardarSubcategoria = async () => {
    try {
      await axios.put(
        `https://ecommerce-backend-self-phi.vercel.app/categorias/${editandoSubcategoria.categoriaId}/subcategorias/${editandoSubcategoria.id}`,
        { nombre: editandoSubcategoria.nombre }
      );
      setCategorias(
        categorias.map((categoria) =>
          categoria.id === editandoSubcategoria.categoriaId
            ? {
                ...categoria,
                subcategorias: categoria.subcategorias.map((subcat) =>
                  subcat.id === editandoSubcategoria.id ? editandoSubcategoria : subcat
                ),
              }
            : categoria
        )
      );
      setEditandoSubcategoria(null);
      setSuccessMessage('Subcategoría actualizada correctamente');
    } catch (error) {
      console.error('Error al actualizar la subcategoría:', error);
    }
  };

  const handleEliminarSubcategoria = async (categoriaId, subcategoriaId) => {
    try {
      await axios.delete(
        `https://ecommerce-backend-self-phi.vercel.app/categorias/${categoriaId}/subcategorias/${subcategoriaId}`
      );
      setCategorias(
        categorias.map((categoria) =>
          categoria.id === categoriaId
            ? {
                ...categoria,
                subcategorias: categoria.subcategorias.filter(
                  (subcat) => subcat.id !== subcategoriaId
                ),
              }
            : categoria
        )
      );
      setSuccessMessage('Subcategoría eliminada correctamente');
    } catch (error) {
      console.error('Error al eliminar la subcategoría:', error);
    }
  };



  const handleAgregarSubcategoria = async (categoriaId) => {
    if (!nuevaSubcategoria) {
      return; 
    }
  
    try {
      const response = await axios.post(
        `https://ecommerce-backend-self-phi.vercel.app/categorias/${categoriaId}/subcategorias`,
        { nombre: { nombre: nuevaSubcategoria } } 
      );
  
      setCategorias(
        categorias.map((categoria) =>
          categoria.id === categoriaId
            ? {
                ...categoria,
                subcategorias: [...categoria.subcategorias, response.data],
              }
            : categoria
        )
      );
  
      setNuevaSubcategoria('');
      setSuccessMessage('Subcategoría agregada correctamente');
    } catch (error) {
      console.error('Error al agregar la subcategoría:', error);
      setSuccessMessage('Error al agregar la subcategoría');
    }
  };
  


  return (
    <div>
      <BannerDash
        title="Configuración de textos"
        formattedDate={formattedDate}
        text="Dashboard"
      />
      <div className="container">
        <div className='row'>
          <div className='col-xl-4 col-lg-4 col-md-6' onClick={() => setActiveSection('textos')} style={{ cursor: 'pointer' }}>
            <CardDashComp title="Textos Banner" />
          </div>
          <div className='col-xl-4 col-lg-4 col-md-6' onClick={() => setActiveSection('terminos')} style={{ cursor: 'pointer' }}>
            <CardDashComp title="Términos y condiciones" />
          </div>
          <div className='col-xl-4 col-lg-4 col-md-6' onClick={() => setActiveSection('faqs')} style={{ cursor: 'pointer' }}>
            <CardDashComp title="Preguntas Frecuentes" />
          </div>
          <div className='col-xl-4 col-lg-4 col-md-6' onClick={() => setActiveSection('comprasMayoristas')} style={{ cursor: 'pointer' }}>
            <CardDashComp title="Compras Mayoristas" />
          </div>
          <div className='col-xl-4 col-lg-4 col-md-6' onClick={() => setActiveSection('categorias')} style={{ cursor: 'pointer' }}>
            <CardDashComp title="Categorías" />
          </div>
        </div>
      </div>



              {activeSection === 'comprasMayoristas' && (
          <div className="config-comp">
            <h2>Compras Mayoristas</h2>
            <label htmlFor="nombre">Nombre:</label>
            <input
              type="text"
              id="nombre"
              value={nuevaCompra.nombre}
              onChange={(e) => setNuevaCompra({ ...nuevaCompra, nombre: e.target.value })}
            />
            <label htmlFor="imagen">URL Imagen:</label>
            <input
              type="text"
              id="imagen"
              value={nuevaCompra.imagen}
              onChange={(e) => setNuevaCompra({ ...nuevaCompra, imagen: e.target.value })}
            />
            <label htmlFor="whatsapp">WhatsApp:</label>
            <input
              type="text"
              id="whatsapp"
              value={nuevaCompra.whatsapp}
              onChange={(e) => setNuevaCompra({ ...nuevaCompra, whatsapp: e.target.value })}
            />
            <button className="btn-config-texts" type="button" onClick={handleAgregarCompra}>Agregar Agente</button>

            <div className="lista-compras">
              {comprasMayoristas.map((compra) => (
                <div key={compra.id} className="compra-item">
                  {editandoCompra && editandoCompra.id === compra.id ? (
                    <div>
                      <input
                        type="text"
                        value={editandoCompra.nombre}
                        onChange={(e) => setEditandoCompra({ ...editandoCompra, nombre: e.target.value })}
                        placeholder="Editar nombre"
                      />
                      <input
                        type="text"
                        value={editandoCompra.imagen}
                        onChange={(e) => setEditandoCompra({ ...editandoCompra, imagen: e.target.value })}
                        placeholder="Editar URL de imagen"
                      />
                      <input
                        type="text"
                        value={editandoCompra.whatsapp}
                        onChange={(e) => setEditandoCompra({ ...editandoCompra, whatsapp: e.target.value })}
                        placeholder="Editar WhatsApp"
                      />
                      <button onClick={() => handleEditarCompra(compra.id)}>Guardar</button>
                      <button onClick={() => setEditandoCompra(null)}>Cancelar</button>
                    </div>
                  ) : (
                    <div>
                      <p><strong>Nombre:</strong> {compra.nombre}</p>
                      <p><strong>WhatsApp:</strong> {compra.whatsapp}</p>
                      <img src={compra.imagen} alt={compra.nombre} width={100} />
                      <button className='btn-config-texts' onClick={() => setEditandoCompra(compra)}>Editar</button>
                      <button className='btn-config-texts' onClick={() => handleEliminarCompra(compra.id)}>Eliminar</button>
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
        )}

      {activeSection === 'textos' && (
        <div className="config-comp">
          <h2>Configurar Textos</h2>
          <p>Texto banner principal: {texto}</p>
          <p>Texto banner fondo negro (adicional): {texto2}</p>
          <form onSubmit={handleSubmitTextos}>
            <label htmlFor="texto">Nuevo Texto 1:</label>
            <input
              type="text"
              id="texto"
              value={nuevoTexto}
              onChange={handleChangeTextos}
              disabled={loadingTextos}
              placeholder={texto}
            />
            <label htmlFor="texto2">Nuevo Texto 2:</label>
            <input
              type="text"
              id="texto2"
              value={nuevoTexto2}
              onChange={handleChangeTextos}
              disabled={loadingTextos}
              placeholder={texto2}
            />
            <button className="btn-config-texts" type="submit" disabled={loadingTextos}>
              {loadingTextos ? 'Guardando...' : 'Guardar Textos'}
            </button>
          </form>
        </div>
      )}

      {activeSection === 'terminos' && (
        <div className="config-comp">
          <h3>Modificar Términos y Condiciones</h3>
          <p>Texto actual de Términos y Condiciones: {terminos}</p>
          <form onSubmit={handleSubmitTerminos}>
            <label htmlFor="terminos">Nuevo Texto de Términos y Condiciones:</label>
            <input
              type="text"
              id="terminos"
              value={nuevoTerminos}
              onChange={handleChangeTerminos}
              disabled={loadingTerminos}
              placeholder={terminos}
            />
            <button className="btn-config-texts" type="submit" disabled={loadingTerminos}>
              {loadingTerminos ? 'Guardando...' : 'Guardar Términos'}
            </button>
          </form>
        </div>
      )}

      {activeSection === 'faqs' && (
        <div className="config-comp">
          <h2>Preguntas Frecuentes</h2>
          <form onSubmit={handleAgregarPregunta}>
            <label htmlFor="nuevaPregunta">Nueva Pregunta:</label>
            <input
              type="text"
              id="nuevaPregunta"
              value={nuevaPregunta}
              onChange={(e) => setNuevaPregunta(e.target.value)}
            />
            <label htmlFor="nuevaRespuesta">Nueva Respuesta:</label>
            <input
              type="text"
              id="nuevaRespuesta"
              value={nuevaRespuesta}
              onChange={(e) => setNuevaRespuesta(e.target.value)}
            />
            <button className='btn-config-texts' type="submit">Agregar Pregunta</button>
          </form>

        <h4 className='title-list-pr-comp'>Listado de preguntas activas</h4>
        
          <div className="lista-preguntas">
            {preguntas.map((pregunta) => (
              <div key={pregunta.id} className="pregunta-item">
                {editandoId === pregunta.id ? (
                  <div>
                    <input
                      type="text"
                      value={editandoPregunta}
                      onChange={(e) => setEditandoPregunta(e.target.value)}
                      placeholder="Editar pregunta"
                    />
                    <input
                      type="text"
                      value={editandoRespuesta}
                      onChange={(e) => setEditandoRespuesta(e.target.value)}
                      placeholder="Editar respuesta"
                    />
                    <button className='btn-config-texts' onClick={() => handleEditarPregunta(pregunta.id)}>Guardar</button>
                    <button className='btn-config-texts' onClick={() => setEditandoId(null)}>Cancelar</button>
                  </div>
                ) : (
                  <div>
                    <p><strong>Pregunta:</strong> {pregunta.pregunta}</p>
                    <p><strong>Respuesta:</strong> {pregunta.respuesta}</p>
                    <button className='btn-config-texts' onClick={() => {
                      setEditandoId(pregunta.id);
                      setEditandoPregunta(pregunta.pregunta);
                      setEditandoRespuesta(pregunta.respuesta);
                    }}>
                      Editar
                    </button>
                    <button className='btn-config-texts' onClick={() => handleEliminarPregunta(pregunta.id)}>Eliminar</button>
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      )}


{activeSection === 'categorias' && (
  <div className="config-comp">
    <h2>Categorías y Subcategorías</h2>

    <form onSubmit={handleAgregarCategoria}>
      <label htmlFor="nuevaCategoria">Nueva Categoría:</label>
      <input
        type="text"
        id="nuevaCategoria"
        value={nuevaCategoria}
        onChange={(e) => setNuevaCategoria(e.target.value)}
        placeholder="Nombre de la categoría"
      />

      <div className="subcategorias-container">
        <label>Subcategorías:</label>
        {nuevasSubcategorias.map((subcat, index) => (
          <div key={index} className="subcategoria-input">
            <input
              type="text"
              value={subcat}
              onChange={(e) => handleSubcategoriaChange(index, e.target.value)}
              placeholder={`Subcategoría ${index + 1}`}
            />
            <button
              className="btn-config-texts"
              type="button"
              onClick={() => eliminarCampoSubcategoria(index)}
            >
              Eliminar
            </button>
          </div>
        ))}
        <button
          className="btn-config-texts"
          type="button"
          onClick={agregarCampoSubcategoria}
        >
          Agregar Subcategoría
        </button>
      </div>

      <button className="btn-config-texts" type="submit">
        Guardar Categoría y Subcategorías
      </button>
    </form>

    <h3 className="title-config-comp-cat">Categorías Activas</h3>
    <ul>
      {categorias.map((categoria) => (
        <li key={categoria.id}>
          {editandoCategoria && editandoCategoria.id === categoria.id ? (
            <div>
              <input
                type="text"
                value={editandoCategoria.nombre}
                onChange={(e) =>
                  setEditandoCategoria({ ...editandoCategoria, nombre: e.target.value })
                }
                placeholder="Editar nombre de la categoría"
              />
              <button onClick={handleGuardarCategoria}>Guardar</button>
              <button onClick={() => setEditandoCategoria(null)}>Cancelar</button>
            </div>
          ) : (
            <div>
              <strong>{categoria.nombre}</strong>
              <button
                className="btn-config-texts span-btn-sub-cat"
                onClick={() => iniciarEdicionCategoria(categoria)}
              >
                Editar
              </button>
              <button
                className="btn-config-texts span-btn-sub-cat"
                onClick={() => handleEliminarCategoria(categoria.id)}
              >
                Eliminar
              </button>
              <button
                className="btn-config-texts span-btn-sub-cat"
                onClick={() => setAgregandoSubcategoriaId(categoria.id)}
              >
                Agregar Subcategoría
              </button>
            </div>
          )}

          {/* Formulario para agregar una nueva subcategoría a la categoría seleccionada */}
          {agregandoSubcategoriaId === categoria.id && (
            <form
              onSubmit={(e) => {
                e.preventDefault();
                handleAgregarSubcategoria(categoria.id);
              }}
            >
              <input
                type="text"
                value={nuevaSubcategoria}
                onChange={(e) => setNuevaSubcategoria(e.target.value)}
                placeholder="Nueva Subcategoría"
              />
              <button className="btn-config-texts" type="submit">
                Guardar Subcategoría
              </button>
            </form>
          )}

          <ul>
            {categoria.subcategorias &&
              categoria.subcategorias.map((subcat) => (
                <li key={subcat.id}>
                  {editandoSubcategoria && editandoSubcategoria.id === subcat.id ? (
                    <div>
                      <input
                        type="text"
                        value={editandoSubcategoria.nombre.nombre}
                        onChange={(e) =>
                          setEditandoSubcategoria({
                            ...editandoSubcategoria,
                            nombre: { nombre: e.target.value },
                          })
                        }
                        placeholder="Editar subcategoría"
                      />
                      <button
                        className="btn-config-texts span-btn-sub-cat"
                        onClick={() => handleGuardarSubcategoria(categoria.id, subcat.id)}
                      >
                        Guardar
                      </button>
                      <button
                        className="btn-config-texts span-btn-sub-cat"
                        onClick={() => setEditandoSubcategoria(null)}
                      >
                        Cancelar
                      </button>
                    </div>
                  ) : (
                    <div>
                      {subcat.nombre.nombre}
                      <button
                        className="btn-config-texts span-btn-sub-cat"
                        onClick={() => iniciarEdicionSubcategoria(categoria.id, subcat)}
                      >
                        Editar
                      </button>
                      <button
                        className="btn-config-texts span-btn-sub-cat"
                        onClick={() => handleEliminarSubcategoria(categoria.id, subcat.id)}
                      >
                        Eliminar
                      </button>
                    </div>
                  )}
                </li>
              ))}
          </ul>
        </li>
      ))}
    </ul>

    {successMessage && <p>{successMessage}</p>}
  </div>
)}



      {successMessage && <p>{successMessage}</p>}
    </div>
  );
};

export default ConfigComp;
