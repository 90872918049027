import React, { Fragment, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

function HeaderMobileHome({
  handleActiveAside,
  handleActiveCart,
  activeAside,
  handleCloseAside,
}) {
  const navigate = useNavigate();
  const [categorias, setCategorias] = useState([]);

  // Fetch categories from the backend
  useEffect(() => {
    const fetchCategorias = async () => {
      try {
        const response = await axios.get('https://ecommerce-backend-self-phi.vercel.app/categorias');
        setCategorias(response.data);
      } catch (error) {
        console.error('Error fetching categories:', error);
      }
    };
    fetchCategorias();
  }, []);

  return (
    <Fragment>
      <div className="header-mobile-home-app">
        <div
          className="card-cart-products span-header-top-mobile"
          onClick={handleActiveAside}
        >
          <img
            className="img-card-cart-products"
            src={require('../images/barras.png')}
            alt="cart"
          />
        </div>
        <div>
          <div className="profile-brand">
            <img
              className="img-ptofile-brand"
              src={require('../images/logo.png')}
              alt="logo"
              onClick={() => navigate('/')}
            />
          </div>
        </div>
        <div className="card-cart-products" onClick={handleActiveCart}>
          <img
            className="img-card-cart-products"
            src={require('../images/cart.png')}
            alt="cart"
          />
        </div>
      </div>

      <div
        className={`aside-header ${activeAside ? 'aside-header-active' : ''}`}
      >
        <img
          className="img-close-cart"
          src={require('../images/close.png')}
          onClick={handleCloseAside}
          alt="cerrar"
        />
        <h1 className="title-top-aside">Nuestros Artículos</h1>
        
        {/* Render dynamic categories */}
        <li
          className="item-aside-header"
          onClick={() => navigate('/productos/categoria/Todos%20los%20artículos')}
        >
          Todos los productos
        </li>
        {categorias.map((categoria) => (
          <Fragment key={categoria.id}>
            <li
              className="item-aside-header"
              onClick={() => navigate(`/productos/categoria/${categoria.nombre}`)}
            >
              {categoria.nombre}
            </li>
            {/* Render subcategories if they exist */}
            {categoria.subcategorias && categoria.subcategorias.length > 0 && (
              <ul className="subcategoria-dropdown">
                {categoria.subcategorias.map((subcat) => (
                  <li
                    key={subcat.id}
                    className="subcategoria-item"
                    onClick={() => navigate(`/productos/categoria/${subcat.nombre.nombre}`)}
                  >
                    - {subcat.nombre.nombre}
                  </li>
                ))}
              </ul>
            )}
          </Fragment>
        ))}

        <h1 className="title-top-aside">Más Información</h1>
        <li
          className="item-aside-header"
          onClick={() => navigate('/nosotros')}
        >
          Sobre Nosotros
        </li>
        <li
          className="item-aside-header"
          onClick={() => navigate('/compras-mayoristas')}
        >
          Compras Mayoristas
        </li>
        <li
          className="item-aside-header"
          onClick={() => navigate('/preguntas-frecuentes')}
        >
          Preguntas Frecuentes
        </li>
        <li
          className="item-aside-header"
          onClick={() => navigate('/contactanos')}
        >
          Contactanos
        </li>
      </div>
    </Fragment>
  );
}

export default HeaderMobileHome;
