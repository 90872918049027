import React from 'react';

function BannerDash({ title, formattedDate, text }) {
  return (
    <div>
      <div className="container-canvas-dashboard-web">
        <div className="banner-dash">
          <h1 className="title-banner-container-text">{title}</h1>
          <div className="item-banner-flex">
            <h1 className="title-item-banner span-text-banner-date">
              {' '}
              {formattedDate}{' '}
            </h1>
          </div>
          <div>
            <h1 className="title-banner-span-copy"> {text} </h1>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BannerDash;
