import React, { Fragment } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import '../styles/tabDash.css';
import { useNavigate } from 'react-router-dom';

function TabDash() {
  const location = useLocation();
  const currentPath = location.pathname;
  const navigate = useNavigate();

  return (
    <Fragment>
      <div className="conatiner-tab-dash">
        <NavLink to="/dashboard/*">
          <div className="container-title-top-dash">
            <h1 className="title-top-dash">Dashboard</h1>
          </div>
        </NavLink>

        <NavLink to="/dashboard/productos">
          <div
            className={`item-tab-dash-options ${
              currentPath === '/dashboard/productos' ? 'span-tab-selected' : ''
            }`}
          >
            <h1 className="text-tab-dash-options">Productos</h1>
          </div>
        </NavLink>

        <NavLink to="/dashboard/ventas">
          <div
            className={`item-tab-dash-options ${
              currentPath === '/dashboard/ventas' ? 'span-tab-selected' : ''
            }`}
          >
            <h1 className="text-tab-dash-options">Ventas</h1>
          </div>
        </NavLink>

        <NavLink to="/dashboard/suscripciones">
          <div
            className={`item-tab-dash-options ${
              currentPath === '/dashboard/suscripciones' ? 'span-tab-selected' : ''
            }`}
          >
            <h1 className="text-tab-dash-options">Susbs</h1>
          </div>
        </NavLink>

        <NavLink to="/dashboard/configuraciones">
          <div
            className={`item-tab-dash-options ${
              currentPath === '/dashboard/configuraciones' ? 'span-tab-selected' : ''
            }`}
          >
            <h1 className="text-tab-dash-options">Configs</h1>
          </div>
        </NavLink>

        <NavLink to="/dashboard/finanzas">
          <div
            className={`item-tab-dash-options ${
              currentPath === '/dashboard/finanzas' ? 'span-tab-selected' : ''
            }`}
          >
            <h1 className="text-tab-dash-options">finanzas</h1>
          </div>
        </NavLink>

        <div className="container-btn-out-tab">
          <button
            className="btn-out-tab"
            onClick={() => navigate('/dashboard')}
          >
            Cerrar Sesión
          </button>
        </div>
      </div>
    </Fragment>
  );
}

export default TabDash;
