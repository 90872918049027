import React from 'react';
import { Rings } from 'react-loader-spinner';
import '../styles/loadingScreen.css'; 

const LoadingScreen = () => {
  return (
    <div className="loading-screen">
      <Rings
        height="100"
        width="100"
        color="#ffffff"
        radius="6"
        visible={true}
      />
    </div>
  );
};

export default LoadingScreen;
