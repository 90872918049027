import React, { useState, useEffect } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import axios from 'axios';

const SubscriptionModal = () => {
  const [show, setShow] = useState(false);
  const [nombre, setNombre] = useState('');
  const [apellido, setApellido] = useState('');
  const [email, setEmail] = useState('');

  useEffect(() => {
    const timer = setTimeout(() => {
      setShow(true);
    }, 3000); 

    return () => clearTimeout(timer); 
  }, []);

  const handleClose = () => setShow(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      await axios.post('https://ecommerce-backend-self-phi.vercel.app/subscripciones', {
        nombre,
        apellido,
        email,
      });
      alert('¡Suscripción exitosa!');
      handleClose();
    } catch (error) {
      console.error('Error al enviar la suscripción:', error);
      alert('Error al suscribirse. Por favor, inténtalo de nuevo.');
    }
  };

  return (
    <Modal className='span-subs-mobile-home' show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Suscribite a nuestra comunidad</Modal.Title>
      </Modal.Header>
      <Modal.Body className='span-subs-home'>
        <Form onSubmit={handleSubmit}>
          <Form.Group controlId="formBasicNombre">
            <Form.Label>Nombre</Form.Label>
            <Form.Control
              type="text"
              placeholder="Ingresa tu nombre"
              value={nombre}
              onChange={(e) => setNombre(e.target.value)}
              required
            />
          </Form.Group>
          <Form.Group controlId="formBasicApellido">
            <Form.Label>Apellido</Form.Label>
            <Form.Control
              type="text"
              placeholder="Ingresa tu apellido"
              value={apellido}
              onChange={(e) => setApellido(e.target.value)}
              required
            />
          </Form.Group>
          <Form.Group controlId="formBasicEmail">
            <Form.Label>Correo electrónico</Form.Label>
            <Form.Control
              type="email"
              placeholder="Ingresa tu correo"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </Form.Group>
          <Button className='span-subs-btn-mobile-home' variant="primary" type="submit">
            Suscribirse
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default SubscriptionModal;
