import React, { useState, useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import '../styles/nosotros.css';
import { Pagination, Navigation } from 'swiper/modules';
import Footer from '../comp/footer';
import { useNavigate } from 'react-router-dom';
import Carrito from '../comp/carrito';
import HeaderMobileHome from '../comp/headerMobileHome';
import axios from 'axios';

const Mayorista = () => {
  const [activeCartShow, setActiveCartShow] = useState(false);
  const [activeAside, setActiveAside] = useState(false);
  const [agentes, setAgentes] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    const fetchAgentes = async () => {
      try {
        const response = await axios.get('https://ecommerce-backend-self-phi.vercel.app/compras-mayoristas');
        setAgentes(response.data);
      } catch (error) {
        console.error('Error fetching agentes:', error);
      }
    };

    fetchAgentes();
  }, []);

  const handleActiveCart = () => {
    setActiveCartShow(true);
  };

  const handleCloseCart = () => {
    setActiveCartShow(false);
  };

  const handleActiveAside = () => {
    setActiveAside(true);
  };

  const handleCloseAside = () => {
    setActiveAside(false);
  };

  const handleContactar = (whatsappNumber) => {
    let formattedNumber = whatsappNumber;
  
    if (!formattedNumber.startsWith('549')) {
      formattedNumber = `549${formattedNumber}`;
    }
  
    const whatsappURL = `https://wa.me/${formattedNumber}`;
    window.open(whatsappURL, '_blank');
  };
  

  return (
    <div className="nosotros-container">
      <HeaderMobileHome
        handleActiveCart={handleActiveCart}
        activeAside={activeAside}
        handleActiveAside={handleActiveAside}
        handleCloseAside={handleCloseAside}
      />

      <Carrito
        activeCart={activeCartShow}
        handleCloseCart={handleCloseCart}
      />

      <div className="header-top-banner-products">
        <h1 className="text-inside-banner-products">
          El envío es gratis a partir de tu compra de 12 unidades en artículos
        </h1>
      </div>

      <header className="header-general-products">
        <img
          className="img-ptofile-brand"
          src={require('../images/logo.png')}
          onClick={() => navigate('/')}
          alt="logo"
        />

        <input
          className="input-header-search-products"
          type="text"
          placeholder="Buscar productos"
        />

        <div className="container-row-header-options">
          <li
            className="option-header"
            onClick={() => navigate('/productos')}
          >
            Articulos
          </li>
          <li
            className="option-header"
            onClick={() => navigate('/productos')}
          >
            Categorías
          </li>
          <li
            className="option-header"
            onClick={() => navigate('/nosotros')}
          >
            Nosotros
          </li>
        </div>

        <div className="card-cart-products" onClick={handleActiveCart}>
          <img
            className="img-card-cart-products"
            src={require('../images/cart.png')}
            alt="cart"
          />
        </div>
      </header>

      {/* Banner */}
      <section className="nosotros-banner">
        <div className="banner-content">
          <h1 className="banner-title">Conocé nuestros precios mayoristas</h1>
          <p className="banner-subtitle">Tenemos precios exclusivos</p>
        </div>
      </section>

      {/* Sección de Información */}
      <section className="nosotros-info">
        <div className="container">
          <h2 className="info-title">Venta Mayorista</h2>
          <p className="info-description">
            Nos complace presentarles nuestra plataforma mayorista diseñada para revolucionar la manera en que gestionas tu negocio. En [Nombre de tu Web], entendemos que la eficiencia, calidad y precio competitivo son clave para tu éxito. Por eso, hemos creado un espacio donde puedes encontrar todo lo que necesitas, en un solo lugar.
          </p>
        </div>
      </section>

      {/* Galería de Agentes */}
      <section className="nosotros-gallery">
        <div className="container">
          <h2 className="gallery-title">Nuestros Agentes</h2>
          <Swiper
            modules={[Pagination, Navigation]}
            spaceBetween={30}
            slidesPerView={4}
            loop={true}
            pagination={{ clickable: true }}
            navigation={true}
            className="swiper-container"
            breakpoints={{
              320: {
                slidesPerView: 1,
              },
              768: {
                slidesPerView: 2,
              },
              1024: {
                slidesPerView: 4,
              },
            }}
          >
            {agentes.map((agente) => (
              <SwiperSlide key={agente.id}>
                <div className="gallery-item-wrapper">
                  <img
                    src={agente.imagen}
                    alt={agente.nombre}
                    className="gallery-item-mayor"
                  />
                  <div className="overlay">
                    <h3 className="agente-name">{agente.nombre}</h3>
                    <button
                      className="btn-contactar"
                      onClick={() => handleContactar(agente.whatsapp)}
                    >
                      Contactar
                    </button>
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </section>

      {/* Footer */}
      <Footer />
    </div>
  );
};

export default Mayorista;
